<template>
  <div id="users">
    <Navbar current="Usuários" />
    <Sidebar />
    <Container col="col-12">
      <Header
        title="Usuários"
        icon="fa-solid fa-user-group"
        :count="totalItemsRegistered"
      >
        <template v-slot:button>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="subscribers_only"
              @change="handleSubscriberFilter"
            />
            <label
              class="form-check-label text-label text-muted"
              for="subscribers_only"
              >Somente assinante</label
            >
          </div>
        </template>
      </Header>
      <div class="card">
        <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState
            v-if="!items.length"
            :totalItemsRegistered="totalItemsRegistered"
            icon="fa-solid fa-user-group"
            title="Nenhum usuário encontrado"
          >
            <template v-slot:noItemsRegistered></template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table
              class="table table-sm table-nowrap card-table table-hover cursor-pointer"
            >
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <router-link
                  tag="tr"
                  v-for="(item, index) in items"
                  v-bind:key="'tr-' + index"
                  :to="`${$paths.users.detail}/${item.id}`"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.account_type || "---" }}</td>
                  <td>{{ item.professionalType || "---" }}</td>
                  <td>
                    <span
                      class="badge"
                      :class="item.subscriptionConfig?.badgeColor"
                      >{{ item.subscriptionConfig?.label }}</span
                    >
                  </td>
                  <!-- <td v-if="item.has_plan">
                    <span class="badge bg-success-soft">Assinante</span>
                  </td>
                  <td v-else>
                    <span class="badge bg-danger-soft">Não assinante</span>
                  </td> -->
                  <td class="text-end">
                    <time>{{
                      $moment(item.created_at).format("DD/MM/YYYY")
                    }}</time>
                  </td>
                </router-link>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted"
            >mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span
          >
        </div>
      </div>
      <div
        class="row justify-content-center"
        v-if="!loadingItems && moreItemsToLoad"
      >
        <div class="col-2">
          <FormButton
            label="Carregar mais"
            :loading="loadingMoreItems"
            @onClick="loadMore"
          />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import UsersController from "./UsersController";
export default UsersController;
</script>
