<template>
  <div>
    <Navbar current="Enviar Email" />
    <Sidebar />
    <Container>
      <Header
        :title="'Enviar Email'"
        :icon="'fa-solid fa-envelope text-primary'"
      />
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <TextInput
                :required="true"
                label="Título"
                :maxLength="TITLE_MAX_LENGTH"
                :model="$v.form.title"
                type="text"
                placeholder="Informe um título para o email"
                :errorMsg="'Informe um título com ao menos 5 caracteres'"
              />
            </div>
            <div class="col-12">
              <TextAreaInput
                :rows="10"
                :required="true"
                label="Descrição"
                :model="$v.form.description"
                type="text"
                :maxLength="DESCRIPTION_MAX_LENGTH"
                placeholder="Informe o conteúdo do email"
              >
                <template v-slot:content>
                  <div class="text-end">
                    <small class="text-muted" style="font-size: 0.7rem"
                      >{{
                        DESCRIPTION_MAX_LENGTH - form.description.length
                      }}
                      caracteres restantes</small
                    >
                  </div>
                </template>
              </TextAreaInput>
            </div>
            <div class="col-12">
              <TextInput
                label="Link"
                :model="$v.form.link"
                type="text"
                placeholder="Informe um link para o email"
                :errorMsg="'Informe um link válido'"
              />
            </div>
            <div class="col-12">
              <IncrementTextInput
                msg="Informe o email e pressione enter para adicioná-lo na lista"
                label="Emails"
                :model="$v.form.emails"
                placeholder="Informe um email"
                :errorMsg="'Informe um email válido'"
                @handleAddItem="addEmail"
                @handleRemoveItem="removeEmail"
                :items="emails"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <span class="text-danger text-center">{{ errorMessage }}</span>
            </div>
          </div>
          <FormButton
            :label="'Enviar'"
            :loading="submitting"
            :disabled="$v.$invalid"
            @onClick="submit"
          />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import SendEmailController from "./SendEmailController";
export default SendEmailController;
</script>
