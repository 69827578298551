<template>
	<div id="content_detail">
		<Navbar current="Detalhes do crash" :link="$paths.crashes.list" :linkName="'Crashes'" />
		<Sidebar />
		<Container>
			<Header title="Detalhes do Crash" />
			<LoadingContainer v-if="loadingItems" />
			<div v-else>
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-12 col-xl-6 justify-content-start d-flex">
								<div v-if="item.os === 'ios'" class="align-items-center d-flex">
									<span class="fab fa-apple fa-2x text-black me-2"></span> Ios
								</div>
								<div v-else class="align-items-center d-flex">
									<span class="fab fa-android fa-2x text-android me-2"></span>
									Android
								</div>
							</div>
							<div class="col-12 col-xl-6 text-end">
								<time>{{
									$moment(item.created_at).format("DD/MM/YYYY[ - ]HH:mm")
								}}</time>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-header">
						<div class="col">Crash report</div>
						<div class="col-auto">
							<button v-if="copied" class="btn btn-link text-success">
								<span class="fas fa-copy text-success"></span> Copiado!
							</button>
							<button v-else @click="copyError" class="btn btn-link text-secondary">
								<span class="fas fa-copy text-dark"></span> Copiar
							</button>
						</div>
					</div>
					<div class="card-body bg-terminal">
						<pre style="font-size: 1rem !important; font-family: monospace">{{
							message
						}}</pre>
					</div>
				</div>
			</div>
		</Container>
	</div>
</template>
<script>
	import CrashDetailController from "./CrashDetailController"
	export default CrashDetailController
</script>
<style scoped>
	.bg-terminal {
		background-color: #222 !important;
		color: white !important;
	}
</style>
