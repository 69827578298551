<template>
  <div id="medicines">
    <Navbar current="Medicamentos" />
    <Sidebar />
    <Container col="col-12">
      <Header
        title="Medicamentos"
        icon="fa-solid fa-pills"
        :count="totalItemsRegistered"
        btnLabel="Adicionar"
        btnIcon="fa-solid fa-plus-circle"
        :btnLink="$paths.medicines.add"
      />
      <div class="card">
        <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState
            v-if="!items.length"
            :totalItemsRegistered="totalItemsRegistered"
            icon="fa-solid fa-pills"
            title="Nenhum medicamento encontrado"
          >
            <template v-slot:noItemsRegistered></template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table
              class="table table-sm table-nowrap card-table table-hover cursor-pointer"
            >
              <TableHead :columns="columns" v-on:sort="sort" />

              <tbody class="list">
                <!-- <router-link tag="tr" v-for="(item, index) in items" v-bind:key="'tr-' + index"
                  :to="`${$paths.medicines.detail}/${item?.drug?.id}`">
                  <td>{{ item?.name }}</td>
                  <td>{{ item?.drug?.compound }}</td>
                  <td class="text-end">{{ item?.drug?.category }}</td>
                </router-link> -->
                <router-link
                  tag="tr"
                  v-for="(item, index) in items"
                  v-bind:key="'tr-' + index"
                  :to="`${$paths.medicines.detail}/${item.id}`"
                >
                  <td>{{ item.compound }}</td>
                  <td>{{ item.category }}</td>
                  <td>{{ item.names }}</td>
                  <td class="text-end" @click.prevent="copyURL(item, index)">
                    <button
                      v-if="item.detail_url_copied"
                      class="btn btn-sm btn-outline-success me-3"
                    >
                      <span class="fas fa-copy"></span> Copiado!
                    </button>
                    <button v-else class="btn btn-sm btn-outline-primary me-3">
                      <span class="fas fa-copy"></span> Copiar URL
                    </button>
                  </td>
                </router-link>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted"
            >mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span
          >
        </div>
      </div>
      <div
        class="row justify-content-center"
        v-if="!loadingItems && moreItemsToLoad"
      >
        <div class="col-2">
          <FormButton
            label="Carregar mais"
            :loading="loadingMoreItems"
            @onClick="loadMore"
          />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import MedicinesController from "./MedicinesController";
export default MedicinesController;
</script>
