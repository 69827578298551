export default {
    watch: {
        "$route.params"(value) {
            this.setupSettings()
        }
    },
    data() {
        return {
            text: '',
            loadingItems: false,
            submitting: false,
            errorMessage: '',
            settingsInfo: {
                key: '',
                title: '',
            },
            tabs: [
                {
                    id: 'termos-de-uso',
                    value: 'Termos',
                    active: this.$route.params.key == 'termos-de-uso'
                },
                {
                    id: 'politica-de-privacidade',
                    value: 'Política de privacidade',
                    active: this.$route.params.key == 'politica-de-privacidade'
                },
            ]
        };
    },
    mounted() {
        this.setupSettings()
    },
    validations: {
        text: '',
    },
    methods: {
        tabClicked(id) {
            let tabs = this.tabs.map(elem => ({ ...elem, active: elem.id == id }))
            this.$router.push({ path: `${this.$paths.settings.edit}/${id}` })
            this.$set(this, 'tabs', tabs)
            this.setupSettings()
        },
        setupSettings() {
            this.loadingItems = true
            let k = this.$route.params.key
            switch (k) {
                case 'termos-de-uso':
                    this.$set(this, 'settingsInfo', {
                        key: 'terms_of_use',
                        title: 'Termos de Uso'
                    })
                    break;
                case 'politica-de-privacidade':
                    this.$set(this, 'settingsInfo', {
                        key: 'privacy_policy',
                        title: 'Política de privacidade'
                    })
                    break;
                default: this.$router.push({ path: this.$paths.not_found })
            }
            this.getData()
        },
        async getData() {
            try {
                const res = await this.$userService.settings(this.settingsInfo.key)
                this.$set(this, 'text', res.data.value)
            } catch (e) {
                console.log(e)
            } finally {
                this.loadingItems = false
            }
        },
        async submit() {
            try {
                this.submitting = true
                this.errorMessage = ''
                const res = await this.$userService.updateSettings(this.settingsInfo.key, this.text)
                this.$eventBus.$emit('showToast', { title: 'Informações salvas', msg: `Informações alteradas com sucesso` })
            } catch (e) {
                this.errorMessage = e.message
            } finally {
                this.submitting = false
            }
        },
        view() {
            let routeData = this.$router.resolve({ path: `${this.$paths.settings.view}/${this.$route.params.key}` })
            window.open(routeData.href, '_blank');
        }
    }
}