export default {
    name: 'Scores',
    mounted() {
        // this.loadingItems = true
        // this.getData()
    },
    data() {
        return {
            items: [],
            totalItemsRegistered: 0,
            countItemsShowing: 0,
            countItemsTotal: 0,
            moreItemsToLoad: false,
            loadingMoreItems: false,
            errorMessage: '',
            loadingItems: false,
            filters: {
                page: 1,
                q: '',
            },
            columns: [
                {
                    name: 'Nome do item',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Descrição',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Data',
                    sortName: null,
                    order: null
                },
            ],
        };
    },
    methods: {
        async getData(loadingMore = false) {
            try {
                if (!loadingMore) {
                    this.$set(this.filters, 'page', 1)
                }
                const res = await this.$userService.list({
                    ...this.filters,
                    orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
                })
                let data = res.data
                if (!loadingMore) {
                    this.$set(this, 'items', data)
                } else {
                    this.$set(this, 'items', this.items.concat(data))
                }
                this.moreItemsToLoad = res.next_page_url != null
                this.countItemsShowing = res.to
                this.countItemsTotal = res.total

                if (this.filters.page == 1 && this.filters.q == '') {
                    // so seta o o totalzao quando nao tem filtro
                    this.$set(this, 'totalItemsRegistered', res.total)
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.loadingItems = false
                this.loadingMoreItems = false
                this.$set(this, 'columns', this.columns.map(c => { return { ...c, loading: false } }))
            }
        },
        loadMore() {
            this.loadingMoreItems = true
            this.$set(this.filters, 'page', this.filters.page + 1)
            this.getData(true)
        },
        search(string) {
            this.$set(this.filters, 'q', string)
            this.getData()
        },
        sort(colIndex) {
            this.$set(this, 'columns', this.$helper.sortCols(this.columns, colIndex))
            this.getData()
        }
    }
};