import { minLength, required } from "vuelidate/lib/validators";
export default {
  name: "Notifications",
  data() {
    return {
      TITLE_MAX_LENGTH: 60,
      DESCRIPTION_MAX_LENGTH: 220,
      errorMessage: "",
      submitting: false,
      form: {
        title: "",
        description: "",
      },
    };
  },
  validations() {
    return {
      form: {
        title: {
          required,
          minLength: minLength(5),
        },
        description: {
          required,
          minLength: minLength(5),
        },
      },
    };
  },
  methods: {
    async submit() {
      try {
        this.submitting = true;
        this.errorMessage = "";
        await this.$userService.sendNotification(this.form);
        this.$eventBus.$emit("showToast", {
          title: "Notificação enviada",
          msg: `A notificação foi enviada aos usuários do aplicativo com sucesso`,
        });
        this.$refs.form.reset();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.submitting = false;
      }
    },
  },
};
