import { NetworkService } from "./NetworkService";

export default class MedicalRecordsService {
  constructor() {
    this.network = new NetworkService();
  }
  list(filters) {
    return this.network.get(this.network.endpoints.medicalRecords, filters);
  }
  show(id) {
    return this.network.get(`${this.network.endpoints.medicalRecords}/${id}`);
  }
}
