import { email, minLength, required, url } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      TITLE_MAX_LENGTH: 60,
      DESCRIPTION_MAX_LENGTH: 220,
      errorMessage: "",
      submitting: false,
      form: {
        title: "",
        description: "",
        link: "",
        emails: "",
      },
      emails: [],
    };
  },
  validations() {
    return {
      form: {
        title: {
          required,
          minLength: minLength(5),
        },
        description: {
          required,
          minLength: minLength(5),
        },
        link: {
          url,
        },
        emails: {
          email,
        },
      },
    };
  },
  methods: {
    async submit() {
      try {
        this.submitting = true;
        this.errorMessage = "";

        const values = {
          ...this.form,
          emails: this.emails.join(","),
        };
        await this.$userService.sendEmail(values);
        this.$eventBus.$emit("showToast", {
          title: "Email enviado",
          msg: `O email foi enviado aos usuários com sucesso`,
        });
        this.resetForm();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.submitting = false;
      }
    },
    resetForm() {
      this.form = {
        title: "",
        description: "",
        link: "",
        emails: "",
      };
      this.emails = [];
      this.$v.$reset();
    },
    addEmail(email) {
      this.emails.push(email);
      this.form.emails = "";
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
  },
};
