import ListControllerMixin from "../../../utils/ListControllerMixin"

export default {
    mounted() {
        this.loadingItems = true
        this.getAreas()
    },
    mixins: [ListControllerMixin],
    data() {
        return {
            areas: [],
            ref_category_id: null,
            columns: [
                {
                    name: 'Nome do item',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Descrição',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Itens',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Ações',
                    sortName: null,
                    order: null
                }
            ],
        }
    },
    methods: {
        tabClicked(id) {
            let areas = this.areas.map(elem => ({ ...elem, value: elem.name, active: elem.id == id }))
            this.$set(this, 'ref_category_id', id)
            this.$set(this, 'areas', areas)
            this.loadingItems = true
            this.getData()
        },
        async getAreas() {
            try {
                let res = await this.$contentsService.listAreas()
                let data = res.data.map((elem, index) => ({ ...elem, value: elem.name, active: index == 0 }))
                this.$set(this, 'ref_category_id', data[0].id)
                this.$set(this, 'areas', data)
                this.getData()
            } catch (error) {
                console.log(error)
                this.loadingItems = false
                this.loadingMoreItems = false
            }
        },
        fetchPromise() {
            return this.$contentsService.listCategories({
                ...this.filters,
                ref_category_id: this.ref_category_id,
                orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
            })
        },
    }
}