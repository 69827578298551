<template>
  <div id="contents">
    <Navbar current="Conteúdos" />
    <Sidebar />
    <Container col="col-12">
      <Header
        title="Conteúdos"
        icon="fa-regular fa-file-lines"
        :tabs="areas"
        @tabClicked="tabClicked"
        :count="totalItemsRegistered"
      />

      <div class="card">
        <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState
            v-if="!items.length"
            :totalItemsRegistered="totalItemsRegistered"
            icon="fa-regular fa-file-lines"
            title="Nenhuma categoria encontrada"
          >
            <template v-slot:noItemsRegistered></template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table
              class="
                table table-sm table-nowrap
                card-table
                table-hover
                cursor-pointer
              "
            >
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <router-link
                  tag="tr"
                  v-for="(item, index) in items"
                  v-bind:key="'tr-' + index"
                  :to="
                    item.count_subcategory
                      ? `${$paths.contents.subcategories.list}/${item.id}`
                      : `${$paths.contents.list}/${item.id}`
                  "
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.count }}</td>
                  <td class="text-end text-subtitle text-primary">
                    Ver itens
                    <i class="fa-solid fa-chevron-right"></i>
                  </td>
                </router-link>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted"
            >mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span
          >
        </div>
      </div>
      <div
        class="row justify-content-center"
        v-if="!loadingItems && moreItemsToLoad"
      >
        <div class="col-2">
          <FormButton
            label="Carregar mais"
            :loading="loadingMoreItems"
            @onClick="loadMore"
          />
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import CategoriesController from "./CategoriesController.js";
export default CategoriesController;
</script>
