import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const currencyMask = createNumberMask({
    prefix: 'R$',
    // allowDecimal: true,
    // includeThousandsSeparator: true,
    // allowNegative: true,
    thousandsSeparatorSymbol: '.',
    // decimalSymbol: ','
});
const phoneMask = '(###) ###-####'
const weekdays = {
    short: [
        'sun',
        'mon',
        'tue',
        'wed',
        'thu',
        'fri',
        'sat',
    ],
    long: [
        'sunday',
        'monday',
        'tusday',
        'wednesday',
        'thursday',
        'friday',
        'saturday'
    ]
}


const countryPhoneCodes = [
    {
        countryCode: "AD",
        countryName: "Andorra",
        phoneCode: "376"
    }, {
        countryCode: "AE",
        countryName: "United Arab Emirates",
        phoneCode: "971"
    }, {
        countryCode: "AF",
        countryName: "Afghanistan",
        phoneCode: "93"
    }, {
        countryCode: "AG",
        countryName: "Antigua and Barbuda",
        phoneCode: "+1-268"
    }, {
        countryCode: "AI",
        countryName: "Anguilla",
        phoneCode: "+1-264"
    }, {
        countryCode: "AL",
        countryName: "Albania",
        phoneCode: "355"
    }, {
        countryCode: "AM",
        countryName: "Armenia",
        phoneCode: "374"
    }, {
        countryCode: "AO",
        countryName: "Angola",
        phoneCode: "244"
    }, {
        countryCode: "AR",
        countryName: "Argentina",
        phoneCode: "54"
    }, {
        countryCode: "AS",
        countryName: "American Samoa",
        phoneCode: "+1-684"
    }, {
        countryCode: "AT",
        countryName: "Austria",
        phoneCode: "43"
    }, {
        countryCode: "AU",
        countryName: "Australia",
        phoneCode: "61"
    }, {
        countryCode: "AW",
        countryName: "Aruba",
        phoneCode: "297"
    }, {
        countryCode: "AX",
        countryName: "Aland Islands",
        phoneCode: "+358-18"
    }, {
        countryCode: "AZ",
        countryName: "Azerbaijan",
        phoneCode: "994"
    }, {
        countryCode: "BA",
        countryName: "Bosnia and Herzegovina",
        phoneCode: "387"
    }, {
        countryCode: "BB",
        countryName: "Barbados",
        phoneCode: "+1-246"
    }, {
        countryCode: "BD",
        countryName: "Bangladesh",
        phoneCode: "880"
    }, {
        countryCode: "BE",
        countryName: "Belgium",
        phoneCode: "32"
    }, {
        countryCode: "BF",
        countryName: "Burkina Faso",
        phoneCode: "226"
    }, {
        countryCode: "BG",
        countryName: "Bulgaria",
        phoneCode: "359"
    }, {
        countryCode: "BH",
        countryName: "Bahrain",
        phoneCode: "973"
    }, {
        countryCode: "BI",
        countryName: "Burundi",
        phoneCode: "257"
    }, {
        countryCode: "BJ",
        countryName: "Benin",
        phoneCode: "229"
    }, {
        countryCode: "BL",
        countryName: "Saint Barthelemy",
        phoneCode: "590"
    }, {
        countryCode: "BM",
        countryName: "Bermuda",
        phoneCode: "+1-441"
    }, {
        countryCode: "BN",
        countryName: "Brunei",
        phoneCode: "673"
    }, {
        countryCode: "BO",
        countryName: "Bolivia",
        phoneCode: "591"
    }, {
        countryCode: "BQ",
        countryName: "Bonaire, Saint Eustatius and Saba ",
        phoneCode: "599"
    }, {
        countryCode: "BR",
        countryName: "Brazil",
        phoneCode: "55"
    }, {
        countryCode: "BS",
        countryName: "Bahamas",
        phoneCode: "+1-242"
    }, {
        countryCode: "BT",
        countryName: "Bhutan",
        phoneCode: "975"
    }, {
        countryCode: "BW",
        countryName: "Botswana",
        phoneCode: "267"
    }, {
        countryCode: "BY",
        countryName: "Belarus",
        phoneCode: "375"
    }, {
        countryCode: "BZ",
        countryName: "Belize",
        phoneCode: "501"
    }, {
        countryCode: "CA",
        countryName: "Canada",
        phoneCode: "1"
    }, {
        countryCode: "CC",
        countryName: "Cocos Islands",
        phoneCode: "61"
    }, {
        countryCode: "CD",
        countryName: "Democratic Republic of the Congo",
        phoneCode: "243"
    }, {
        countryCode: "CF",
        countryName: "Central African Republic",
        phoneCode: "236"
    }, {
        countryCode: "CG",
        countryName: "Republic of the Congo",
        phoneCode: "242"
    }, {
        countryCode: "CH",
        countryName: "Switzerland",
        phoneCode: "41"
    }, {
        countryCode: "CI",
        countryName: "Ivory Coast",
        phoneCode: "225"
    }, {
        countryCode: "CK",
        countryName: "Cook Islands",
        phoneCode: "682"
    }, {
        countryCode: "CL",
        countryName: "Chile",
        phoneCode: "56"
    }, {
        countryCode: "CM",
        countryName: "Cameroon",
        phoneCode: "237"
    }, {
        countryCode: "CN",
        countryName: "China",
        phoneCode: "86"
    }, {
        countryCode: "CO",
        countryName: "Colombia",
        phoneCode: "57"
    }, {
        countryCode: "CR",
        countryName: "Costa Rica",
        phoneCode: "506"
    }, {
        countryCode: "CU",
        countryName: "Cuba",
        phoneCode: "53"
    }, {
        countryCode: "CV",
        countryName: "Cape Verde",
        phoneCode: "238"
    }, {
        countryCode: "CW",
        countryName: "Curacao",
        phoneCode: "599"
    }, {
        countryCode: "CX",
        countryName: "Christmas Island",
        phoneCode: "61"
    }, {
        countryCode: "CY",
        countryName: "Cyprus",
        phoneCode: "357"
    }, {
        countryCode: "CZ",
        countryName: "Czech Republic",
        phoneCode: "420"
    }, {
        countryCode: "DE",
        countryName: "Germany",
        phoneCode: "49"
    }, {
        countryCode: "DJ",
        countryName: "Djibouti",
        phoneCode: "253"
    }, {
        countryCode: "DK",
        countryName: "Denmark",
        phoneCode: "45"
    }, {
        countryCode: "DM",
        countryName: "Dominica",
        phoneCode: "+1-767"
    }, {
        countryCode: "DO",
        countryName: "Dominican Republic",
        phoneCode: "+1-809 and 1-829"
    }, {
        countryCode: "DZ",
        countryName: "Algeria",
        phoneCode: "213"
    }, {
        countryCode: "EC",
        countryName: "Ecuador",
        phoneCode: "593"
    }, {
        countryCode: "EE",
        countryName: "Estonia",
        phoneCode: "372"
    }, {
        countryCode: "EG",
        countryName: "Egypt",
        phoneCode: "20"
    }, {
        countryCode: "EH",
        countryName: "Western Sahara",
        phoneCode: "212"
    }, {
        countryCode: "ER",
        countryName: "Eritrea",
        phoneCode: "291"
    }, {
        countryCode: "ES",
        countryName: "Spain",
        phoneCode: "34"
    }, {
        countryCode: "ET",
        countryName: "Ethiopia",
        phoneCode: "251"
    }, {
        countryCode: "FI",
        countryName: "Finland",
        phoneCode: "358"
    }, {
        countryCode: "FJ",
        countryName: "Fiji",
        phoneCode: "679"
    }, {
        countryCode: "FK",
        countryName: "Falkland Islands",
        phoneCode: "500"
    }, {
        countryCode: "FM",
        countryName: "Micronesia",
        phoneCode: "691"
    }, {
        countryCode: "FO",
        countryName: "Faroe Islands",
        phoneCode: "298"
    }, {
        countryCode: "FR",
        countryName: "France",
        phoneCode: "33"
    }, {
        countryCode: "GA",
        countryName: "Gabon",
        phoneCode: "241"
    }, {
        countryCode: "GB",
        countryName: "United Kingdom",
        phoneCode: "44"
    }, {
        countryCode: "GD",
        countryName: "Grenada",
        phoneCode: "+1-473"
    }, {
        countryCode: "GE",
        countryName: "Georgia",
        phoneCode: "995"
    }, {
        countryCode: "GF",
        countryName: "French Guiana",
        phoneCode: "594"
    }, {
        countryCode: "GG",
        countryName: "Guernsey",
        phoneCode: "+44-1481"
    }, {
        countryCode: "GH",
        countryName: "Ghana",
        phoneCode: "233"
    }, {
        countryCode: "GI",
        countryName: "Gibraltar",
        phoneCode: "350"
    }, {
        countryCode: "GL",
        countryName: "Greenland",
        phoneCode: "299"
    }, {
        countryCode: "GM",
        countryName: "Gambia",
        phoneCode: "220"
    }, {
        countryCode: "GN",
        countryName: "Guinea",
        phoneCode: "224"
    }, {
        countryCode: "GP",
        countryName: "Guadeloupe",
        phoneCode: "590"
    }, {
        countryCode: "GQ",
        countryName: "Equatorial Guinea",
        phoneCode: "240"
    }, {
        countryCode: "GR",
        countryName: "Greece",
        phoneCode: "30"
    }, {
        countryCode: "GT",
        countryName: "Guatemala",
        phoneCode: "502"
    }, {
        countryCode: "GU",
        countryName: "Guam",
        phoneCode: "+1-671"
    }, {
        countryCode: "GW",
        countryName: "Guinea-Bissau",
        phoneCode: "245"
    }, {
        countryCode: "GY",
        countryName: "Guyana",
        phoneCode: "592"
    }, {
        countryCode: "HK",
        countryName: "Hong Kong",
        phoneCode: "852"
    }, {
        countryCode: "HM",
        countryName: "Heard Island and McDonald Islands",
        phoneCode: " "
    }, {
        countryCode: "HN",
        countryName: "Honduras",
        phoneCode: "504"
    }, {
        countryCode: "HR",
        countryName: "Croatia",
        phoneCode: "385"
    }, {
        countryCode: "HT",
        countryName: "Haiti",
        phoneCode: "509"
    }, {
        countryCode: "HU",
        countryName: "Hungary",
        phoneCode: "36"
    }, {
        countryCode: "ID",
        countryName: "Indonesia",
        phoneCode: "62"
    }, {
        countryCode: "IE",
        countryName: "Ireland",
        phoneCode: "353"
    }, {
        countryCode: "IL",
        countryName: "Israel",
        phoneCode: "972"
    }, {
        countryCode: "IM",
        countryName: "Isle of Man",
        phoneCode: "+44-1624"
    }, {
        countryCode: "IN",
        countryName: "India",
        phoneCode: "91"
    }, {
        countryCode: "IO",
        countryName: "British Indian Ocean Territory",
        phoneCode: "246"
    }, {
        countryCode: "IQ",
        countryName: "Iraq",
        phoneCode: "964"
    }, {
        countryCode: "IR",
        countryName: "Iran",
        phoneCode: "98"
    }, {
        countryCode: "IS",
        countryName: "Iceland",
        phoneCode: "354"
    }, {
        countryCode: "IT",
        countryName: "Italy",
        phoneCode: "39"
    }, {
        countryCode: "JE",
        countryName: "Jersey",
        phoneCode: "+44-1534"
    }, {
        countryCode: "JM",
        countryName: "Jamaica",
        phoneCode: "+1-876"
    }, {
        countryCode: "JO",
        countryName: "Jordan",
        phoneCode: "962"
    }, {
        countryCode: "JP",
        countryName: "Japan",
        phoneCode: "81"
    }, {
        countryCode: "KE",
        countryName: "Kenya",
        phoneCode: "254"
    }, {
        countryCode: "KG",
        countryName: "Kyrgyzstan",
        phoneCode: "996"
    }, {
        countryCode: "KH",
        countryName: "Cambodia",
        phoneCode: "855"
    }, {
        countryCode: "KI",
        countryName: "Kiribati",
        phoneCode: "686"
    }, {
        countryCode: "KM",
        countryName: "Comoros",
        phoneCode: "269"
    }, {
        countryCode: "KN",
        countryName: "Saint Kitts and Nevis",
        phoneCode: "+1-869"
    }, {
        countryCode: "KP",
        countryName: "North Korea",
        phoneCode: "850"
    }, {
        countryCode: "KR",
        countryName: "South Korea",
        phoneCode: "82"
    }, {
        countryCode: "KW",
        countryName: "Kuwait",
        phoneCode: "965"
    }, {
        countryCode: "KY",
        countryName: "Cayman Islands",
        phoneCode: "+1-345"
    }, {
        countryCode: "KZ",
        countryName: "Kazakhstan",
        phoneCode: "7"
    }, {
        countryCode: "LA",
        countryName: "Laos",
        phoneCode: "856"
    }, {
        countryCode: "LB",
        countryName: "Lebanon",
        phoneCode: "961"
    }, {
        countryCode: "LC",
        countryName: "Saint Lucia",
        phoneCode: "+1-758"
    }, {
        countryCode: "LI",
        countryName: "Liechtenstein",
        phoneCode: "423"
    }, {
        countryCode: "LK",
        countryName: "Sri Lanka",
        phoneCode: "94"
    }, {
        countryCode: "LR",
        countryName: "Liberia",
        phoneCode: "231"
    }, {
        countryCode: "LS",
        countryName: "Lesotho",
        phoneCode: "266"
    }, {
        countryCode: "LT",
        countryName: "Lithuania",
        phoneCode: "370"
    }, {
        countryCode: "LU",
        countryName: "Luxembourg",
        phoneCode: "352"
    }, {
        countryCode: "LV",
        countryName: "Latvia",
        phoneCode: "371"
    }, {
        countryCode: "LY",
        countryName: "Libya",
        phoneCode: "218"
    }, {
        countryCode: "MA",
        countryName: "Morocco",
        phoneCode: "212"
    }, {
        countryCode: "MC",
        countryName: "Monaco",
        phoneCode: "377"
    }, {
        countryCode: "MD",
        countryName: "Moldova",
        phoneCode: "373"
    }, {
        countryCode: "ME",
        countryName: "Montenegro",
        phoneCode: "382"
    }, {
        countryCode: "MF",
        countryName: "Saint Martin",
        phoneCode: "590"
    }, {
        countryCode: "MG",
        countryName: "Madagascar",
        phoneCode: "261"
    }, {
        countryCode: "MH",
        countryName: "Marshall Islands",
        phoneCode: "692"
    }, {
        countryCode: "MK",
        countryName: "Macedonia",
        phoneCode: "389"
    }, {
        countryCode: "ML",
        countryName: "Mali",
        phoneCode: "223"
    }, {
        countryCode: "MM",
        countryName: "Myanmar",
        phoneCode: "95"
    }, {
        countryCode: "MN",
        countryName: "Mongolia",
        phoneCode: "976"
    }, {
        countryCode: "MO",
        countryName: "Macao",
        phoneCode: "853"
    }, {
        countryCode: "MP",
        countryName: "Northern Mariana Islands",
        phoneCode: "+1-670"
    }, {
        countryCode: "MQ",
        countryName: "Martinique",
        phoneCode: "596"
    }, {
        countryCode: "MR",
        countryName: "Mauritania",
        phoneCode: "222"
    }, {
        countryCode: "MS",
        countryName: "Montserrat",
        phoneCode: "+1-664"
    }, {
        countryCode: "MT",
        countryName: "Malta",
        phoneCode: "356"
    }, {
        countryCode: "MU",
        countryName: "Mauritius",
        phoneCode: "230"
    }, {
        countryCode: "MV",
        countryName: "Maldives",
        phoneCode: "960"
    }, {
        countryCode: "MW",
        countryName: "Malawi",
        phoneCode: "265"
    }, {
        countryCode: "MX",
        countryName: "Mexico",
        phoneCode: "52"
    }, {
        countryCode: "MY",
        countryName: "Malaysia",
        phoneCode: "60"
    }, {
        countryCode: "MZ",
        countryName: "Mozambique",
        phoneCode: "258"
    }, {
        countryCode: "NA",
        countryName: "Namibia",
        phoneCode: "264"
    }, {
        countryCode: "NC",
        countryName: "New Caledonia",
        phoneCode: "687"
    }, {
        countryCode: "NE",
        countryName: "Niger",
        phoneCode: "227"
    }, {
        countryCode: "NF",
        countryName: "Norfolk Island",
        phoneCode: "672"
    }, {
        countryCode: "NG",
        countryName: "Nigeria",
        phoneCode: "234"
    }, {
        countryCode: "NI",
        countryName: "Nicaragua",
        phoneCode: "505"
    }, {
        countryCode: "NL",
        countryName: "Netherlands",
        phoneCode: "31"
    }, {
        countryCode: "NO",
        countryName: "Norway",
        phoneCode: "47"
    }, {
        countryCode: "NP",
        countryName: "Nepal",
        phoneCode: "977"
    }, {
        countryCode: "NR",
        countryName: "Nauru",
        phoneCode: "674"
    }, {
        countryCode: "NU",
        countryName: "Niue",
        phoneCode: "683"
    }, {
        countryCode: "NZ",
        countryName: "New Zealand",
        phoneCode: "64"
    }, {
        countryCode: "OM",
        countryName: "Oman",
        phoneCode: "968"
    }, {
        countryCode: "PA",
        countryName: "Panama",
        phoneCode: "507"
    }, {
        countryCode: "PE",
        countryName: "Peru",
        phoneCode: "51"
    }, {
        countryCode: "PF",
        countryName: "French Polynesia",
        phoneCode: "689"
    }, {
        countryCode: "PG",
        countryName: "Papua New Guinea",
        phoneCode: "675"
    }, {
        countryCode: "PH",
        countryName: "Philippines",
        phoneCode: "63"
    }, {
        countryCode: "PK",
        countryName: "Pakistan",
        phoneCode: "92"
    }, {
        countryCode: "PL",
        countryName: "Poland",
        phoneCode: "48"
    }, {
        countryCode: "PM",
        countryName: "Saint Pierre and Miquelon",
        phoneCode: "508"
    }, {
        countryCode: "PN",
        countryName: "Pitcairn",
        phoneCode: "870"
    }, {
        countryCode: "PR",
        countryName: "Puerto Rico",
        phoneCode: "+1-787 and 1-939"
    }, {
        countryCode: "PS",
        countryName: "Palestinian Territory",
        phoneCode: "970"
    }, {
        countryCode: "PT",
        countryName: "Portugal",
        phoneCode: "351"
    }, {
        countryCode: "PW",
        countryName: "Palau",
        phoneCode: "680"
    }, {
        countryCode: "PY",
        countryName: "Paraguay",
        phoneCode: "595"
    }, {
        countryCode: "QA",
        countryName: "Qatar",
        phoneCode: "974"
    }, {
        countryCode: "RE",
        countryName: "Reunion",
        phoneCode: "262"
    }, {
        countryCode: "RO",
        countryName: "Romania",
        phoneCode: "40"
    }, {
        countryCode: "RS",
        countryName: "Serbia",
        phoneCode: "381"
    }, {
        countryCode: "RU",
        countryName: "Russia",
        phoneCode: "7"
    }, {
        countryCode: "RW",
        countryName: "Rwanda",
        phoneCode: "250"
    }, {
        countryCode: "SA",
        countryName: "Saudi Arabia",
        phoneCode: "966"
    }, {
        countryCode: "SB",
        countryName: "Solomon Islands",
        phoneCode: "677"
    }, {
        countryCode: "SC",
        countryName: "Seychelles",
        phoneCode: "248"
    }, {
        countryCode: "SD",
        countryName: "Sudan",
        phoneCode: "249"
    }, {
        countryCode: "SE",
        countryName: "Sweden",
        phoneCode: "46"
    }, {
        countryCode: "SG",
        countryName: "Singapore",
        phoneCode: "65"
    }, {
        countryCode: "SH",
        countryName: "Saint Helena",
        phoneCode: "290"
    }, {
        countryCode: "SI",
        countryName: "Slovenia",
        phoneCode: "386"
    }, {
        countryCode: "SJ",
        countryName: "Svalbard and Jan Mayen",
        phoneCode: "47"
    }, {
        countryCode: "SK",
        countryName: "Slovakia",
        phoneCode: "421"
    }, {
        countryCode: "SL",
        countryName: "Sierra Leone",
        phoneCode: "232"
    }, {
        countryCode: "SM",
        countryName: "San Marino",
        phoneCode: "378"
    }, {
        countryCode: "SN",
        countryName: "Senegal",
        phoneCode: "221"
    }, {
        countryCode: "SO",
        countryName: "Somalia",
        phoneCode: "252"
    }, {
        countryCode: "SR",
        countryName: "Suriname",
        phoneCode: "597"
    }, {
        countryCode: "SS",
        countryName: "South Sudan",
        phoneCode: "211"
    }, {
        countryCode: "ST",
        countryName: "Sao Tome and Principe",
        phoneCode: "239"
    }, {
        countryCode: "SV",
        countryName: "El Salvador",
        phoneCode: "503"
    }, {
        countryCode: "SX",
        countryName: "Sint Maarten",
        phoneCode: "599"
    }, {
        countryCode: "SY",
        countryName: "Syria",
        phoneCode: "963"
    }, {
        countryCode: "SZ",
        countryName: "Swaziland",
        phoneCode: "268"
    }, {
        countryCode: "TC",
        countryName: "Turks and Caicos Islands",
        phoneCode: "+1-649"
    }, {
        countryCode: "TD",
        countryName: "Chad",
        phoneCode: "235"
    }, {
        countryCode: "TG",
        countryName: "Togo",
        phoneCode: "228"
    }, {
        countryCode: "TH",
        countryName: "Thailand",
        phoneCode: "66"
    }, {
        countryCode: "TJ",
        countryName: "Tajikistan",
        phoneCode: "992"
    }, {
        countryCode: "TK",
        countryName: "Tokelau",
        phoneCode: "690"
    }, {
        countryCode: "TL",
        countryName: "East Timor",
        phoneCode: "670"
    }, {
        countryCode: "TM",
        countryName: "Turkmenistan",
        phoneCode: "993"
    }, {
        countryCode: "TN",
        countryName: "Tunisia",
        phoneCode: "216"
    }, {
        countryCode: "TO",
        countryName: "Tonga",
        phoneCode: "676"
    }, {
        countryCode: "TR",
        countryName: "Turkey",
        phoneCode: "90"
    }, {
        countryCode: "TT",
        countryName: "Trinidad and Tobago",
        phoneCode: "+1-868"
    }, {
        countryCode: "TV",
        countryName: "Tuvalu",
        phoneCode: "688"
    }, {
        countryCode: "TW",
        countryName: "Taiwan",
        phoneCode: "886"
    }, {
        countryCode: "TZ",
        countryName: "Tanzania",
        phoneCode: "255"
    }, {
        countryCode: "UA",
        countryName: "Ukraine",
        phoneCode: "380"
    }, {
        countryCode: "UG",
        countryName: "Uganda",
        phoneCode: "256"
    }, {
        countryCode: "UM",
        countryName: "United States Minor Outlying Islands",
        phoneCode: "1"
    }, {
        countryCode: "US",
        countryName: "United States",
        phoneCode: "1"
    }, {
        countryCode: "UY",
        countryName: "Uruguay",
        phoneCode: "598"
    }, {
        countryCode: "UZ",
        countryName: "Uzbekistan",
        phoneCode: "998"
    }, {
        countryCode: "VA",
        countryName: "Vatican",
        phoneCode: "379"
    }, {
        countryCode: "VC",
        countryName: "Saint Vincent and the Grenadines",
        phoneCode: "+1-784"
    }, {
        countryCode: "VE",
        countryName: "Venezuela",
        phoneCode: "58"
    }, {
        countryCode: "VG",
        countryName: "British Virgin Islands",
        phoneCode: "+1-284"
    }, {
        countryCode: "VI",
        countryName: "U.S. Virgin Islands",
        phoneCode: "+1-340"
    }, {
        countryCode: "VN",
        countryName: "Vietnam",
        phoneCode: "84"
    }, {
        countryCode: "VU",
        countryName: "Vanuatu",
        phoneCode: "678"
    }, {
        countryCode: "WF",
        countryName: "Wallis and Futuna",
        phoneCode: "681"
    }, {
        countryCode: "WS",
        countryName: "Samoa",
        phoneCode: "685"
    }, {
        countryCode: "YE",
        countryName: "Yemen",
        phoneCode: "967"
    }, {
        countryCode: "YT",
        countryName: "Mayotte",
        phoneCode: "262"
    }, {
        countryCode: "ZA",
        countryName: "South Africa",
        phoneCode: "27"
    }, {
        countryCode: "ZM",
        countryName: "Zambia",
        phoneCode: "260"
    }, {
        countryCode: "ZW",
        countryName: "Zimbabwe",
        phoneCode: "263"
    }]

export { currencyMask, phoneMask, weekdays, countryPhoneCodes }
