<template>
  <div id="add-admin">
    <Navbar
      current="Criar administrador"
      :link="$paths.admins.list"
      :linkName="'Administradores'"
    />
    <Sidebar />
    <Container>
      <Header title="Criar administrador" />
      <div class="card">
        <div class="card-body">
          <h2 class="text-header">Informações</h2>
          <div class="row">
            <div class="col-12 col-md-6">
              <TextInput
                label="Nome"
                :model="$v.form.name"
                type="text"
                placeholder="Informe um nome"
              />
            </div>
            <div class="col-12 col-md-6">
              <TextInput
                label="Email"
                :model="$v.form.email"
                type="email"
                placeholder="nome@email.com"
              />
            </div>
            <div class="col-12 col-md-6">
              <PasswordInput
                label="Senha"
                :model="$v.form.password"
                placeholder="Informe uma senha"
              />
            </div>
            <div class="col-12 col-md-6">
              <PasswordInput
                label="Confirme a senha"
                :model="$v.form.passwordConfirmation"
                placeholder="Confirme a senha"
              />
            </div>
          </div>
          <form>
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <FormButton
              label="Criar conta"
              :loading="submitting"
              :disabled="$v.$invalid"
              @onClick="submit"
            />
          </form>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import AddAdminsController from "./AddAdminsController";
export default AddAdminsController;
</script>
<style scoped>
</style>
