import { NetworkService } from "./NetworkService";

export default class UserService {
  constructor() {
    this.network = new NetworkService();
  }

  static PROFESSIONAL_TYPES = {
    doctor: "Médico",
    dentist: "Dentista",
    other: "Outro",
  };

  list = (filters) => {
    return this.network.get(this.network.endpoints.users, filters);
  };
  create = (params) => {
    return this.network.post(this.network.endpoints.users, params);
  };
  update = (id, params) => {
    return this.network.put(`${this.network.endpoints.users}/${id}`, params);
  };
  show = (id, params) => {
    return this.network.get(`${this.network.endpoints.users}/${id}`, params);
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.users}/${id}`);
  };
  login = async (params) => {
    let res = await this.network.post(this.network.endpoints.login, params);
    if (res.data.type == "admin") {
      return res;
    } else {
      throw { status: false, message: "Credenciais inválidas" };
    }
  };
  forgotPassword = (params) => {
    return this.network.post(this.network.endpoints.forgotPassword, params);
  };
  logout = () => {
    return this.network.put(`${this.network.endpoints.users}/logout`);
  };
  settings = (key) => {
    return this.network.get(`${this.network.endpoints.settings}/${key}`);
  };
  updateSettings = (key, value) => {
    return this.network.put(`${this.network.endpoints.settings}/${key}`, {
      value,
    });
  };
  homeInfo = () => {
    return this.network.get(`${this.network.endpoints.homeInfo}`);
  };
  listCrashes = () => {
    return this.network.get(`${this.network.endpoints.appCrashes}`);
  };
  showCrash = (id) => {
    return this.network.get(`${this.network.endpoints.appCrashes}/${id}`);
  };
  planDetails = (params) => {
    return this.network.get(`${this.network.endpoints.planDetails}`, params);
  };
  subscribeUser = (params) => {
    return this.network.post(this.network.endpoints.subscribeUser, params);
  };
  unsubscribeUser = (params) => {
    return this.network.post(this.network.endpoints.unsubscribeUser, params);
  };
  getStatus = (key) => {
    switch (key) {
      case "active":
      case "trialing":
      case "canceled_trialing":
      case "canceled_active":
        return "Assinante";
      case "incomplete":
        return "Incompleto";
      case "incomplete_expired":
        return "Expirado";
      case "past_due":
        return "Vencido";
      case "unpaid":
        return "Não pago";
      case "paused":
        return "Pausado";
      default:
        break;
    }
  };
  sendNotification = (params) => {
    return this.network.post(
      `${this.network.endpoints.sendNotification}`,
      params
    );
  };
  sendEmail = (params) => {
    return this.network.post(`${this.network.endpoints.sendEmail}`, params);
  };
}
