export default {
    name: "Home",
    data() {
        return {
            loadingItems: false,
            info: {},
            currentMonth: this.$moment().locale("pt").format('MMMM'),
        }
    },
    mounted() {
        this.loadingItems = true
        this.getUsers()

    },
    methods: {
        async getUsers() {
            try {
                let res = await this.$userService.homeInfo()
                this.$set(this, 'info', res.data)
            } catch (error) {
                console.log('ERRO NO GET USER NA HOME', error)
            } finally {
                this.loadingItems = false
            }
        }
    }
};