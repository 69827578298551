import ListControllerMixin from "../../utils/ListControllerMixin";

export default {
    mixins: [ListControllerMixin],
    mounted() {
        this.loadingItems = true
        this.getData()
    },
    data() {
        return {
            columns: [
                {
                    name: 'Nome',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Médico responsável',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Total de receitas',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Data de criação',
                    sortName: null,
                    order: null
                },
            ],
        };
    },
    methods: {
        fetchPromise() {
            return this.$patientsService.list({
                ...this.filters,
                orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
            })
        }
    }
};