import ListControllerMixin from "../../utils/ListControllerMixin";

export default {
    mixins: [ListControllerMixin],
    mounted() {
        this.loadingItems = true
        this.getData()
    },
    data() {
        return {
            columns: [
                {
                    name: 'Médico',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Paciente',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Tipo',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Data de criação',
                    sortName: null,
                    order: null
                },
                {
                    name: 'PDF',
                    sortName: null,
                    order: null
                },
                {
                    name: '',
                    sortName: null,
                    order: null
                },
            ],
        };
    },
    methods: {
        prepareResponse(res) {
            let data = res.map(elem => (
                {
                    ...elem,
                    type_label: this.$prescriptionsService.formatType(elem.type)
                }
            ))
            console.log(data)
            return data
        },
        fetchPromise() {
            return this.$prescriptionsService.list({
                ...this.filters,
                orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
            })
        },
        openPDF(item) {
            if (item.pdf_url) {
                window.open(`${process.env.VUE_APP_IMG_BASE_URL}${item.pdf_url}`, '_blank');
            }
        }
    }
};