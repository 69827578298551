<template>
  <div id="patients">
    <Navbar current="Pacientes" />
    <Sidebar />
    <Container col="col-12">
      <Header
        title="Pacientes"
        icon="fa-solid fa-user-injured"
        :count="totalItemsRegistered"
        subtitle="Registro de todos os pacientes"
      />
      <div class="card">
        <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState
            v-if="!items.length"
            :totalItemsRegistered="totalItemsRegistered"
            icon="fa-solid fa-user-injured"
            title="Nenhum paciente encontrado"
          >
            <template v-slot:noItemsRegistered></template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table
              class="
                table table-sm table-nowrap
                card-table
                table-hover
                cursor-pointer
              "
            >
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <router-link
                  tag="tr"
                  v-for="(item, index) in items"
                  v-bind:key="'tr-' + index"
                  :to="`${$paths.patients.detail}/${item.id}`"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item?.profissional?.name }}</td>
                  <td>{{ item?.recipe_count }}</td>
                  <td class="text-end">
                    <time>{{
                      $moment(item.created_at).format("DD/MM/YYYY")
                    }}</time>
                  </td>
                </router-link>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted"
            >mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span
          >
        </div>
      </div>
      <div
        class="row justify-content-center"
        v-if="!loadingItems && moreItemsToLoad"
      >
        <div class="col-2">
          <FormButton
            label="Carregar mais"
            :loading="loadingMoreItems"
            @onClick="loadMore"
          />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import PatientsController from "./PatientsController";
export default PatientsController;
</script>
