<template>
  <div>
    <ModalComponent
      :title="title === null || title === undefined ? 'Confirmar acão' : title"
      id="confirmAlertModal"
      v-on:modalClosed="alertClosed"
    >
      <template v-slot:body>
        {{
          message === null || message === undefined
            ? "Tem certeza que deseja continuar ?"
            : message
        }}
      </template>
      <template v-slot:footer>
        <button
          type="button"
          @click="cancelAction"
          class="btn btn-outline-primary w-15"
        >
          Não
        </button>
        <button
          type="button"
          @click="confirmAction"
          class="btn btn-primary w-15"
        >
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Sim
        </button>
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import ConfirmationAlertController from "./ConfirmationAlertController";
export default ConfirmationAlertController;
</script>
<style scoped></style>
