import { required, requiredIf, url } from "vuelidate/lib/validators";
import { Modal } from "bootstrap";

export default {
  name: "AddContent",
  mounted() {
    this.sortItemsModal = new Modal(document.getElementById("sort_items"));
    this.getTabs();
    this.getContentCategory();
    this.getCalculatorTypes();
  },
  data() {
    return {
      id: this.$route.params.id,
      errorMessage: "",
      submitting: false,
      loading: false,
      parentCategory: null,
      calculatorTypes: [],
      form: {
        content_category_id: this.$route.query.content_category_id,
        name: "",
        // description: '',
        only_subscribers: false,
        items: [],
      },
      sortItems: {},
      currentTab: null,
      currentItem: null,
      tabs: [],
      contentTypes: [
        {
          title: "Texto Personalizado",
          icon: "fa-regular fa-file-code",
          type: "html",
        },
        {
          title: "Texto",
          icon: "fa-regular fa-file-lines",
          type: "text",
        },
        {
          title: "Vídeo",
          icon: "fa-solid fa-video",
          type: "video",
        },
        {
          title: "Imagem",
          icon: "fa-solid fa-image",
          type: "image",
        },
        {
          title: "Calculadora",
          icon: "fa-solid fa-calculator",
          type: "calculator",
        },
        // {
        //     title: 'Escore',
        //     icon: 'fa-solid fa-ruler-vertical',
        //     type: 'score'
        // },
      ],
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        // description: {},
        items: {
          $each: {
            fakeId: {},
            tab_id: {},
            type: { required },
            name: { required },
            // name: {
            //     required: requiredIf((model) => model.type == 'text' || model.type == 'html')
            // },
            description: {
              required: requiredIf(
                (model) => model.type == "text" || model.type == "html"
              ),
            },
            url: {
              url,
              required: requiredIf((model) => model.type == "video"),
            },
            image: {
              required: requiredIf((model) => model.type == "image"),
            },
            calculator_type: {
              required: requiredIf((model) => model.type == "calculator"),
            },
            score_id: {
              required: requiredIf((model) => model.type == "score"),
            },
          },
        },
      },
    };
  },
  methods: {
    async getTabs() {
      try {
        let res = await this.$contentsService.listTabs();
        let tabs = [
          { id: "", name: "Geral", active: true },
          ...res.data.map((elem) => ({ ...elem, active: false })),
        ];
        this.$set(this, "tabs", tabs);
      } catch (error) {
        console.log(error);
      }
    },
    async getContentCategory() {
      try {
        let res = await this.$contentsService.showCategory(
          this.$route.query.content_category_id
        );
        res.data.type = "category";
        this.$set(this, "parentCategory", res.data);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingItems = false;
      }
    },
    async getCalculatorTypes() {
      try {
        let res = await this.$contentsService.listCalculatorTypes();
        this.$set(this, "calculatorTypes", res.data);
      } catch (error) {
        console.log("ERRO NO GET CALCULATOR TYPES", error);
      }
    },
    async submit() {
      try {
        this.errorMessage = "";
        let values = this.form;
        values.only_subscribers = values.only_subscribers ? 1 : 0;
        values.items = values.items.map((elem, index) => {
          let item = { ...elem, order: elem.order ? elem.order : index + 1 };
          if (elem.type == "video") {
            return { ...item, short: elem.short ? 1 : 0 };
          }
          return item;
        });
        // console.log(values);
        // return;
        this.submitting = true;
        let res = await this.$contentsService.create(values);
        if (res.status) {
          this.$eventBus.$emit("showToast", {
            title: "Conteúdo criado",
            msg: `Conteúdo criado com sucesso`,
          });
          this.$router.back();
        }
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.submitting = false;
      }
    },
    addContent(type) {
      let newContent = {
        fakeId: Math.floor(Math.random() * 1000 + 1),
        type,
        tab_id: "",
        name: "",
        description: "",
      };
      switch (type) {
        case "video":
          newContent = { ...newContent, url: "", short: false };
          break;
        case "image":
          newContent = { ...newContent, image: "" };
          break;
        case "calculator":
          newContent = { ...newContent, calculator_type: "" };
          break;
        case "score":
          newContent = { ...newContent, score_id: "" };
          break;
      }
      this.$set(this.form, "items", [...this.form.items, newContent]);
    },
    removeContent(index) {
      let items = [...this.form.items];
      items.splice(index, 1);
      this.$set(this.form, "items", items);
    },
    getTypeLabel(type) {
      switch (type) {
        case "html":
          return "Texto Personalizado";
        case "text":
          return "Texto";
        case "video":
          return "Video";
        case "image":
          return "Imagem";
        case "calculator":
          return "Calculadora";
        case "score":
          return "Escore";
      }
    },
    getTypeIcon(type) {
      switch (type) {
        case "text":
          return "fa-regular fa-file-lines";
        case "video":
          return "fa-solid fa-video";
        case "image":
          return "fa-solid fa-image";
        case "calculator":
          return "fa-solid fa-calculator";
        case "score":
          return "fa-solid fa-ruler-vertical";
      }
    },
    cropEnd(image, index) {
      let f = { ...this.form };
      f.items[index].image = image.file;
      this.$set(this, "form", f);
    },
    setShortVideoContent(index) {
      this.$set(this.form.items[index], "short", !this.form.items[index].short);
    },
    setPremiumContent() {
      this.$set(this.form, "only_subscribers", !this.form.only_subscribers);
    },
    setIframe(event, index) {
      let value = event.target.value;
      let parsedUrl = this.$helper.youtubeParser(value);
      let url = parsedUrl.replace("watch?v=", "embed/").split("&")[0];

      this.$set(this.form.items[index], "url", url);

      if (url != undefined || url != "") {
        var regExp =
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
          // Do anything for being valid
          // if need to change the url to embed url then use below line
          this.hasLink = true;
        } else {
          this.hasLink = false;
          // Do anything for not being valid
        }
      }
    },
    selectCalculatorType(value, index) {
      this.$set(this.form.items[index], "calculator_type", value);
    },
    selectTab(value, index) {
      this.$set(this.form.items[index], "tab_id", value);
    },
    setupSortItems(tab, fakeId = "") {
      if (fakeId) {
        this.$set(this, "currentItem", fakeId);
      }

      let si = { ...this.sortItems };
      let items = [...this.form.items];
      this.tabs.map((tabElem, tabIndex) => {
        si[tabElem.name] = items
          .filter((itemElem) => itemElem.tab_id == tabElem.id)
          .sort((a, b) => a?.order - b?.order);
      });
      this.$set(this, "sortItems", si);

      let tabs = this.tabs.map((elem) => ({ ...elem, active: elem.id == tab }));
      this.$set(this, "tabs", tabs);

      let currentTab = this.tabs.find((e) => e.id == tab);
      this.$set(this, "currentTab", currentTab);
    },
    changeOrder() {
      let si = { ...this.sortItems };
      si[this.currentTab?.name] = si[this.currentTab?.name].map(
        (elem, index) => ({ ...elem, order: parseInt(index + 1) })
      );
      this.$set(this, "sortItems", si);

      let items = [];
      this.tabs.map((elem) => {
        items = [...items, ...si[elem.name]];
      });
      this.$set(this.form, "items", items);
    },
    handleSort() {
      this.sortItemsModal.hide();
    },
  },
};
