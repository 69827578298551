import { email, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';


export default {
    name: 'ForgotPassword',
    mounted() {
        this.$store.commit('clearSession')
    },
    data() {
        return {
            errorMessage: '',
            submitting: false,
            form: {
                email: '',
            }
        };
    },
    validations: {
        form: {
            email: {
                required,
                email,
            },
        }
    },
    methods: {
        ...mapActions([
            'saveSession',
        ]),
        async login() {
            this.errorMessage = ''
            this.submitting = true
            try {
                const res = await this.$userService.forgotPassword({ ...this.form })
                console.log({ res })
                // await this.saveSession(res)
                this.$eventBus.$emit('showToast', { title: 'Email de recuperação enviado', msg: `Um link de recuperação foi enviado para seu email, verifique seu email.` })
                this.$router.push(this.$paths.login)
            } catch (e) {
                console.log(e)
                this.errorMessage = e.message
            } finally {
                this.submitting = false
            }
        }
    }
};