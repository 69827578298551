import { required, requiredIf, url } from "vuelidate/lib/validators";
import { Modal } from "bootstrap";

export default {
  mounted() {
    this.loading = true;
    this.sortItemsModal = new Modal(document.getElementById("sort_items"));
    this.getTabs();
    this.getData();
    this.getContentCategory();
    this.getCalculatorTypes();
  },
  data() {
    return {
      id: this.$route.params.id,
      item: null,
      errorMessage: "",
      submitting: false,
      submittingItem: false,
      submittingOrder: false,
      loading: false,
      parentCategory: null,
      calculatorTypes: [],
      form: {
        content_category_id: this.$route.query.content_category_id,
        name: "",
        // description: '',
        only_subscribers: false,
        items: [],
      },
      sortItems: {},
      currentTab: null,
      currentItem: null,
      tabs: [],
      contentTypes: [
        {
          title: "Texto Personalizado",
          icon: "fa-regular fa-file-code",
          type: "html",
        },
        {
          title: "Texto",
          icon: "fa-regular fa-file-lines",
          type: "text",
        },
        {
          title: "Vídeo",
          icon: "fa-solid fa-video",
          type: "video",
        },
        {
          title: "Imagem",
          icon: "fa-solid fa-image",
          type: "image",
        },
        {
          title: "Calculadora",
          icon: "fa-solid fa-calculator",
          type: "calculator",
        },
        // {
        //     title: 'Escore',
        //     icon: 'fa-solid fa-ruler-vertical',
        //     type: 'score'
        // },
      ],
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        // description: {},
        only_subscribers: {},
        items: {
          $each: {
            id: {},
            fakeId: {},
            tab_id: {},
            type: { required },
            name: { required },
            // name: {
            //     required: requiredIf((model) => model.type == 'text' || model.type == 'html')
            // },
            description: {
              required: requiredIf(
                (model) => model.type == "text" || model.type == "html"
              ),
            },
            url: {
              url,
              required: requiredIf((model) => model.type == "video"),
            },
            image: {
              required: requiredIf((model) => model.type == "image"),
            },
            calculator_type: {
              required: requiredIf((model) => model.type == "calculator"),
            },
            score_id: {
              required: requiredIf((model) => model.type == "score"),
            },
            short: {},
            update: false,
          },
        },
      },
    };
  },
  methods: {
    async getTabs() {
      try {
        let res = await this.$contentsService.listTabs();
        let tabs = [
          { id: "", name: "Geral", active: true },
          ...res.data.map((elem) => ({ ...elem, active: false })),
        ];
        this.$set(this, "tabs", tabs);
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      try {
        const res = await this.$contentsService.show(this.id);
        let data = {
          content_category_id: res.data.content_category_id,
          name: res.data.name,
          // description: res.data.description,
          only_subscribers: res.data.only_subscribers,
          items: res.data.items,
        };

        console.log(data.items);

        data.items = data.items.map((elem, index) => {
          let item = {
            id: elem.id,
            fakeId: Math.floor(Math.random() * 1000 + 1),
            order: elem.order ? elem.order : index + 1,
            tab_id: elem.tab_id ? elem.tab_id : "",
            type: elem.type,
            name: elem.name,
            description: elem.description,
          };
          switch (elem.type) {
            case "video":
              item = { ...item, url: elem.url, short: elem.short };
              break;
            case "image":
              item = { ...item, image: elem.url };
              break;
            case "calculator":
              item = { ...item, calculator_type: elem.calculator_type };
              break;
            case "score":
              item = { ...item, score_id: elem.score_id };
              break;
          }
          return item;
        });

        // console.log(data)

        this.$set(this, "form", data);
        this.$set(this, "item", data);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async getContentCategory() {
      try {
        let res = await this.$contentsService.showCategory(
          this.$route.query.content_category_id
        );
        res.data.type = "category";
        this.$set(this, "parentCategory", res.data);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingItems = false;
      }
    },
    async submit() {
      try {
        this.errorMessage = "";
        let values = { ...this.form };
        let contentItems = values.items.filter((elem) => !elem.id);
        let originalItems = values.items.filter((elem) => elem.id);
        this.submitting = true;
        if (contentItems.length) {
          let items = await this.$contentsService.createContentItems(
            this.id,
            contentItems,
            originalItems.length
          );
          console.log(items);
        }
        delete values.items;
        let res = await this.$contentsService.update(this.id, values);
        console.log(res);
        if (res.status) {
          this.$eventBus.$emit("showToast", {
            title: "Conteúdo criado",
            msg: `Conteúdo criado com sucesso`,
          });
          this.$router.back();
        }
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.submitting = false;
      }
    },
    async getCalculatorTypes() {
      try {
        let res = await this.$contentsService.listCalculatorTypes();
        console.log({ res });
        this.$set(this, "calculatorTypes", res.data);
      } catch (error) {
        console.log("ERRO NO GET CALCULATOR TYPES", error);
      }
    },
    editContent(index) {
      let items = [...this.form.items];
      items[index].update = !items[index].update;
      this.$set(this.form, "items", items);
    },
    addContent(type) {
      let newContent = {
        fakeId: Math.floor(Math.random() * 1000 + 1),
        type,
        tab_id: "",
        name: "",
        description: "",
      };

      switch (type) {
        case "video":
          newContent = { ...newContent, url: "", short: false };
          break;
        case "image":
          newContent = { ...newContent, image: "" };
          break;
        case "calculator":
          newContent = { ...newContent, calculator_type: "" };
          break;
        case "score":
          newContent = { ...newContent, score_id: "" };
          break;
      }
      this.$set(this.form, "items", [...this.form.items, newContent]);
    },
    async updateContent(index, id) {
      try {
        this.submittingItem = true;
        let res = await this.$contentsService.updateContentItem(
          id,
          this.form.items[index]
        );
        console.log(res);
        if (res.status) {
          this.getData();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.submittingItem = false;
      }
    },
    async updateContentItems() {
      try {
        this.submittingOrder = true;
        let values = { ...this.form };
        let res = await this.$contentsService.updateContentItemsOrder(
          this.id,
          values.items
        );
        console.log(res);
        this.sortItemsModal.hide();
        this.getData();
      } catch (error) {
        console.log(error);
      } finally {
        this.submittingOrder = false;
      }
    },
    async removeContent(index, id = null) {
      try {
        if (id) {
          let res = await this.$contentsService.deleteContentItem(id);
          if (res.status) {
            let items = [...this.form.items];
            items.splice(index, 1);
            this.$set(this.form, "items", items);
          }
        } else {
          let items = [...this.form.items];
          items.splice(index, 1);
          this.$set(this.form, "items", items);
        }
      } catch (error) {
        console.log(error);
      }
    },
    getTypeLabel(type) {
      switch (type) {
        case "html":
          return "Texto Personalizado";
        case "text":
          return "Texto";
        case "video":
          return "Video";
        case "image":
          return "Imagem";
        case "calculator":
          return "Calculadora";
        case "score":
          return "Escore";
      }
    },
    getTypeIcon(type) {
      switch (type) {
        case "text":
          return "fa-regular fa-file-lines";
        case "video":
          return "fa-solid fa-video";
        case "image":
          return "fa-solid fa-image";
        case "calculator":
          return "fa-solid fa-calculator";
        case "score":
          return "fa-solid fa-ruler-vertical";
      }
    },
    cropEnd(image, index) {
      let f = { ...this.form };
      f.items[index].image = image.file;
      this.$set(this, "form", f);
    },
    setIframe(event, index) {
      let value = event.target.value;
      let parsedUrl = this.$helper.youtubeParser(value);
      let url = parsedUrl.replace("watch?v=", "embed/").split("&")[0];

      this.$set(this.form.items[index], "url", url);

      if (url != undefined || url != "") {
        var regExp =
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
          // Do anything for being valid
          // if need to change the url to embed url then use below line
          this.hasLink = true;
        } else {
          this.hasLink = false;
          // Do anything for not being valid
        }
      }
    },
    selectCalculatorType(value, index) {
      this.$set(this.form.items[index], "calculator_type", value);
    },
    selectTab(value, index) {
      this.$set(this.form.items[index], "tab_id", value);
    },
    setupSortItems(tab, fakeId = "") {
      if (fakeId) {
        this.$set(this, "currentItem", fakeId);
      }

      let si = { ...this.sortItems };
      let items = [...this.form.items];
      this.tabs.map((tabElem, tabIndex) => {
        si[tabElem.name] = items
          .filter((itemElem) => itemElem.tab_id == tabElem.id)
          .sort((a, b) => a?.order - b?.order);
      });
      this.$set(this, "sortItems", si);

      let tabs = this.tabs.map((elem) => ({ ...elem, active: elem.id == tab }));
      this.$set(this, "tabs", tabs);

      let currentTab = this.tabs.find((e) => e.id == tab);
      this.$set(this, "currentTab", currentTab);
    },
    changeOrder() {
      let si = { ...this.sortItems };
      si[this.currentTab?.name] = si[this.currentTab?.name].map(
        (elem, index) => ({ ...elem, order: parseInt(index + 1) })
      );
      this.$set(this, "sortItems", si);

      let items = [];
      this.tabs.map((elem) => {
        items = [...items, ...si[elem.name]];
      });
      this.$set(this.form, "items", items);
    },
  },
};
