import { NetworkService } from "./NetworkService";

export default class UserService {
  constructor() {
    this.network = new NetworkService();
  }
  list = (filters) => {
    return this.network.get(this.network.endpoints.medicines, filters);
  };
  create = (params) => {
    return this.network.post(this.network.endpoints.medicines, params);
  };
  update = (id, params) => {
    return this.network.put(
      `${this.network.endpoints.medicines}/${id}`,
      params
    );
  };
  show = (id) => {
    return this.network.get(`${this.network.endpoints.medicines}/${id}`);
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.medicines}/${id}`);
  };
  listCategories = (filters) => {
    return this.network.get(this.network.endpoints.medicineCategories, filters);
  };
  listUsesInLactation = (filters) => {
    return this.network.get(this.network.endpoints.usesInLactation, filters);
  };
  listUsesInPregnancy = (filters) => {
    return this.network.get(this.network.endpoints.usesInPregnancy, filters);
  };
  listMedicineNames = (filters) => {
    return this.network.get(this.network.endpoints.medicineNames, filters);
  };
  deleteMedicineName = (id) => {
    return this.network.delete(`${this.network.endpoints.medicineNames}/${id}`);
  };
  updateMedicineNames = (id) => {
    return this.network.put(`${this.network.endpoints.medicineNames}/${id}`);
  };
}
