import { toggleModal } from "../../components/ModalComponent.vue";
import ListControllerMixin from "../../utils/ListControllerMixin";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Calculators",
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      toggleModal: () => toggleModal("calculator-update"),
      columns: [
        {
          name: "Nome do item",
          sortName: null,
          order: null,
        },
        {
          name: "Data",
          sortName: null,
          order: null,
        },
      ],
      form: {
        name: "",
      },
      currentItem: null,
      submitting: false,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  methods: {
    fetchPromise() {
      return this.$contentsService.listCalculatorTypes({
        ...this.filters,
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
    handleEdit(item) {
      this.currentItem = item;
      this.form.name = item.name;
      this.toggleModal();
    },
    async handleUpdate() {
      try {
        this.submitting = true;
        await this.$contentsService.updateCalculatorType(
          this.currentItem.id,
          this.form
        );
        this.toggleModal();
        await this.getData();
      } catch (error) {
        console.log(error);
      } finally {
        this.submitting = false;
        this.currentItem = null;
        this.form.name = "";
        this.$v.$reset();
      }
    },
  },
};
