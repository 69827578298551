<template>
  <div id="edit-admins">
    <Navbar
      current="Editar administrador"
      :link="$paths.admins.list"
      :sublink="`${$paths.admins.detail}/${id}`"
      :linkName="'Administradores'"
      :sublinkName="'Detalhes do administrador'"
    />
    <Sidebar />
    <Container>
      <Header title="Editar Administrador" />
      <div class="card">
        <div class="card-body">
          <h2 class="text-header">Informações</h2>
          <form>
            <TextInput
              label="Nome"
              type="text"
              :model="$v.form.name"
              placeholder="Nome"
            />
            <TextInput :value="item.email" :readOnly="true" label="Email" />
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <FormButton
              label="Editar"
              :loading="submitting"
              :disabled="$v.$invalid"
              @onClick="submit"
            />
          </form>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import EditAdminsController from "./EditAdminsController";
export default EditAdminsController;
</script>
<style scoped>
</style>
