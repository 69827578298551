<template>
  <button
    @click.prevent="clicked()"
    :disabled="disabled || loading"
    class="btn btn-lg w-100 btn-primary my-3"
  >
    <span
      v-if="loading"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
    <span class="text-action">
      {{ label }}
    </span>
  </button>
</template>

<script>
export default {
  name: "FormButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Field name",
    },
  },
  methods: {
    clicked() {
      this.$emit("onClick");
    },
  },
};
</script>
