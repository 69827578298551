
<template>
  <div id="categoires">
    <Navbar
      :link="$paths.contents.categories.list"
      linkName="Conteúdos"
      :current="parentCategory?.name"
    />
    <Sidebar />
    <Container col="col-12">
      <Header
        :title="parentCategory?.name"
        :subtitle="parentCategory?.description"
      >
        <template v-slot:button>
          <!-- ADD CONTENT -->
          <router-link
            tag="a"
            :to="`${$paths.contents.add}?content_category_id=${id}`"
            class="btn btn-primary text-subtitle me-3"
            v-if="totalItemsRegistered > 0"
          >
            <i class="fa-solid fa-plus-circle me-1"></i>
            Conteúdo
          </router-link>

          <!-- EDIT CATEGORY -->
          <button
            class="btn btn-outline-primary text-subtitle"
            data-bs-toggle="modal"
            data-bs-target="#edit_subcategory"
            @click="setupForm"
          >
            <i class="fa-solid fa-pen-to-square me-1"></i>
            Editar
          </button>
        </template>
      </Header>

      <div class="card">
        <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState
            v-if="!items.length"
            :totalItemsRegistered="totalItemsRegistered"
            icon="fa-regular fa-file-lines"
          >
            <template v-slot:button>
              <div class="text-center mb-3">
                <!-- ADD SUBCATEGORY -->
                <button
                  class="btn btn-outline-primary text-subtitle me-3"
                  data-bs-toggle="modal"
                  data-bs-target="#create_subcategory"
                >
                  <i class="fa-solid fa-plus-circle me-1"></i>
                  Subcategoria
                </button>

                <!-- ADD CONTENT -->
                <router-link
                  tag="a"
                  :to="`${$paths.contents.add}?content_category_id=${id}`"
                  class="btn btn-primary text-subtitle"
                >
                  <i class="fa-solid fa-plus-circle me-1"></i>
                  Conteúdo
                </router-link>
              </div>
              <span class="text-caption text-dark25">
                <i class="fa-solid fa-exclamation-triangle"></i>
                Uma vez que um conteúdo for criado, não será mais possível criar
                subcategorias dentro do mesmo lugar.
              </span>
            </template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table
              class="table table-sm table-nowrap card-table table-hover cursor-pointer"
            >
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <router-link
                  tag="tr"
                  v-for="(item, index) in items"
                  v-bind:key="'tr-' + index"
                  :to="`${$paths.contents.detail}/${item.id}`"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.description }}</td>
                  <td class="text-end">
                    <time>{{
                      $moment(item.created_at).format("DD/MM/YYYY")
                    }}</time>
                  </td>
                </router-link>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted"
            >mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span
          >
        </div>
      </div>
      <div
        class="row justify-content-center"
        v-if="!loadingItems && moreItemsToLoad"
      >
        <div class="col-2">
          <FormButton
            label="Carregar mais"
            :loading="loadingMoreItems"
            @onClick="loadMore"
          />
        </div>
      </div>
    </Container>
    <ModalComponent id="create_subcategory" title="Criar subcategoria">
      <template v-slot:body>
        <TextInput
          :model="$v.form.name"
          label="Titulo da subcategoria"
          type="text"
          placeholder="Informe um título"
        />
        <TextInput
          :model="$v.form.description"
          label="Descrição"
          type="text"
          placeholder="Informe uma descrição"
        />
        <FormButton
          label="Criar subcategoria"
          :loading="submitting"
          :disabled="$v.$invalid"
          @onClick="createSubcategory"
        />
      </template>
    </ModalComponent>

    <ModalComponent id="edit_subcategory" title="Editar subcategoria">
      <template v-slot:body>
        <TextInput
          :model="$v.form.name"
          label="Titulo da subcategoria"
          type="text"
          placeholder="Informe um título"
        />
        <TextInput
          :model="$v.form.description"
          label="Descrição"
          type="text"
          placeholder="Informe uma descrição"
        />
        <FormButton
          label="Remover"
          :loading="loadingDelete"
          @onClick="deleteSubcategory"
          class="btn-del mb-1"
        />
        <FormButton
          label="Editar subcategoria"
          :loading="submitting"
          :disabled="$v.$invalid"
          @onClick="editSubcategory"
        />
      </template>
    </ModalComponent>
  </div>
</template>
    
<script>
import ContentsController from "./ContentsController.js";
export default ContentsController;
</script>

