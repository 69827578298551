import { required } from "vuelidate/lib/validators";
import { toggleModal } from "../../utils/Helpers";
import ListControllerMixin from "../../utils/ListControllerMixin";
import AIFilesService from "../../services/AIFilesService";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      filters: {
        page: 1,
        q: "",
      },
      columns: [
        {
          name: "Título",
          sortName: null,
          order: null,
        },
        {
          name: "Arquivo",
          sortName: null,
          order: null,
        },
        {
          name: "Status",
          sortName: null,
          order: null,
        },
        {
          name: "Data de criação",
          sortName: null,
          order: null,
        },
        {
          name: "",
          sortName: null,
          order: null,
        },
      ],
      submitting: false,
      form: {
        title: "",
        file: null,
      },
      errorMessage: "",
    };
  },
  validations: {
    form: {
      title: {
        required,
      },
      file: {
        required,
      },
    },
  },
  methods: {
    fetchPromise() {
      return this.$aiFilesService.list({
        ...this.filters,
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
    prepareResponse(data) {
      return data.map((elem) => {
        const file = elem.path.split("/");
        const fileName = file[file.length - 1];
        return {
          ...elem,
          fileName,
          status: AIFilesService.STATUS[elem.vs_status],
          statusColor: AIFilesService.STATUS_COLOR[elem.vs_status],
        };
      });
    },
    addFile() {
      toggleModal("add_file_modal");
    },
    fileSelected(value) {
      this.form.file = value.file;
    },
    async handleAddFile() {
      try {
        this.errorMessage = "";
        console.log(this.form);
        this.submitting = true;
        const res = await this.$aiFilesService.create(this.form);
        console.log(res);
        this.$eventBus.$emit("showToast", {
          title: "Arquivo adicionado",
          msg: `Arquivo adicionado com sucesso`,
        });
        toggleModal("add_file_modal");
        this.$v.$reset();
        this.form = {
          title: "",
          file: "",
        };
        this.getData();
      } catch (error) {
        console.log(error);
        this.errorMessage = error?.message;
      } finally {
        this.submitting = false;
      }
    },
    async handleDelete(item) {
      this.$eventBus.$emit("showConfirmationAlert", {
        title: "Remover arquivo",
        message: "Deseja remover esse arquivo permanentemente?",
        confirmCallback: async () => {
          try {
            await this.$aiFilesService.delete(item.id);
            this.getData();
            this.$eventBus.$emit("showToast", {
              title: "Arquivo removido",
              msg: `Arquivo removido com sucesso`,
            });
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
    openPDF(item) {
      if (item.path) {
        window.open(
          `${process.env.VUE_APP_IMG_BASE_URL}${item.path}`,
          "_blank"
        );
      }
    },
  },
};
