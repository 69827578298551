<template>
  <div id="medicine_detail">
    <Navbar current="Detalhe do medicamento" :link="$paths.medicines.list" :linkName="'Medicamentos'" />
    <Sidebar />
    <Container>
      <Header title="Detalhe do medicamento" :btnLink="`${$paths.medicines.edit}/${id}`" btnLabel="Editar"
        btnIcon="fa-solid fa-pen-to-square">
        <template v-slot:button>
          <button v-if="copied" class="btn btn-outline-success me-3">
            <span class="fas fa-copy"></span> Copiado!
          </button>
          <button v-else @click="copyURL" class="btn btn-outline-primary me-3">
            <span class="fas fa-copy"></span> Copiar URL
          </button>
          <button @click="handleDelete" class="btn btn-del lift ms-3">
            <div class="fa-solid fa-times-circle me-1"></div>
            Remover
          </button>
        </template>
      </Header>

      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="card">

          <div class="card-body">
            <h2 class="text-header">Informações do medicamento</h2>
            <div class="row">
              <div class="col-12">
                <label class="text-subtitle mb-3">Substancia</label>
                <TextInput :readOnly="true" :value="item.compound" />
              </div>
            </div>
          </div>

          <!-- <hr class="my-0" />

            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <label class="text-subtitle mb-3">Nome comercial</label>
                  <TextInput label="Nomes comerciais de referência" :readOnly="true" :value="item.name" />
                  <TextInput label="Nomes comerciais similares" :readOnly="true" :value="item.similar_names" />
                </div>
              </div>
            </div> -->

          <hr class="my-0" />

          <div class="card-body">
            <div class="row">
              <div class="col-12 ">
                <label class="text-subtitle mb-3">Classe Terapêutica</label>
                <TextInput :readOnly="true" :value="item.category" />
              </div>
            </div>
          </div>

          <hr class="my-0" />

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <label class="text-subtitle mb-3">Mecanismo de ação</label>
                <div class="textarea-html" v-html="item.mechanism_of_action"></div>
                <!-- <TextAreaInput
                    :readOnly="true"
                    :value="item.mechanism_of_action"
                  /> -->
              </div>
            </div>
          </div>

          <hr class="my-0" />

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <label class="text-subtitle mb-3">Tipo de receituário</label>
                <TextInput :readOnly="true" :value="item.type_of_prescription" />
              </div>
            </div>
          </div>

          <hr class="my-0" />

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <label class="text-subtitle mb-3">Apresentações comerciais</label>
                <div class="textarea-html" v-html="item.commercial_presentation"></div>
                <!-- <TextAreaInput
                    :readOnly="true"
                    :value="item.commercial_presentation"
                  /> -->
              </div>
            </div>
          </div>

          <hr class="my-0" />

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <label class="text-subtitle mb-3">Posologia</label>
                <div class="textarea-html" v-html="item.treatment_methods"></div>

                <!-- <TextAreaInput
                    :readOnly="true"
                    @input="renderHTML(item.treatment_methods)"
                    :value="item.treatment_methods"
                  /> -->
              </div>
            </div>
          </div>

          <hr class="my-0" />

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <label class="text-subtitle mb-3">Uso na gestação</label>
                <TextAreaInput :rows="2" :readOnly="true" :value="item.pregnancy_use" />
              </div>
            </div>
          </div>

          <hr class="my-0" />

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <label class="text-subtitle mb-3">Uso na lactação</label>
                <TextAreaInput :rows="2" :readOnly="true" :value="item.lactation_use" />
              </div>
            </div>
          </div>

          <hr class="my-0" />

          <div class="card-body">
            <label class="text-subtitle mb-3">Efeitos colaterais</label>
            <div v-for="(side_effect, index) in item.side_effects" v-bind:key="'side_effect_' + index">
              <div class="row">
                <div class="col">
                  <TextInput :readOnly="true" :value="side_effect.name" />
                </div>
              </div>
            </div>
          </div>

          <hr class="my-0" />

          <div class="card-body">
            <label class="text-subtitle mb-3">Referências</label>
            <div v-for="(reference, index) in item.references" v-bind:key="'reference_' + index">
              <div class="row">
                <div class="col">
                  <TextInput :readOnly="true" :value="reference.name" />
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-12 col-md-7">
                <p class="text-subtitle">
                  Nomes comerciais
                </p>
              </div>
              <div class="col-12 col-md-5 text-end">
                <button v-if="isEditing" class="btn btn-sm btn-success me-3" @click.prevent="updateNames">
                  <span v-if="submitting" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                  Salvar
                </button>

                <button class="btn btn-sm btn-info" @click.prevent="editItems">
                  {{ isEditing ? "Cancelar" : "Editar" }}
                </button>
              </div>
            </div>


            <div v-for="(item, index) in $v.form.names.$each.$iter" v-bind:key="'name_' + index">

              <div class="row">
                <div class="col">
                  <TextInput :model="item.name" :value="item.name.$model" :readOnly="!isEditing" type="text"
                    placeholder="Referência" />
                </div>
                <div v-if="isEditing" class="col-auto" @click="removeItem(index)">
                  <div
                    class="form-control form-control-lg py-0 px-4 justify-content-center align-items-center d-flex cursor-pointer">
                    <span v-if="currentItemId == item.$model.id && loadingDelete"
                      class="spinner-border text-danger spinner-border-sm" role="status" aria-hidden="true"></span>
                    <i v-else class="fas fa-trash-alt text-danger"></i>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isEditing" class="text-subtitle text-primary cursor-pointer" @click="addItem">
              <i class="fas fa-circle-plus"></i>
              Adicionar
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import MedicineDetailController from "./MedicineDetailController";
export default MedicineDetailController;
</script>
<style>
.textarea-html {
  background-color: #f2f2f2;
  border: 1px #d9d9d9 solid;
  border-radius: 10px;
  padding: 0.5rem 0.75rem;
}
</style>
  