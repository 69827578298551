import moment from "moment";
import {
  countryPhoneCodes,
  currencyMask,
  phoneMask,
  weekdays,
} from "./HelperVariables";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function timeMask(value) {
  const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
  const minutes = [/[0-5]/, /[0-9]/];
  return value.length > 2 ? [...hours, ":", ...minutes] : hours;
}

function pad(d) {
  return d ? (d < 10 ? "0" + d.toString() : d.toString()) : "";
}

/**
 *
 * @param {*} originArray array de itens em que deseja procurar
 * @param {*} properties qual property do obj deseja comparar a string, se for vazio, busca em todas q forem string ou number
 * @param {*} string  a string de busca
 * @returns retorna o mesmo arrray mas com uma propriedade hidden, pra esconder da lista
 */
function searchString(originArray, string, ...keys) {
  try {
    let properties = keys;
    if (!string) {
      originArray.map((r) => (r.hidden = false));
      return originArray;
    }
    if (!originArray || originArray.length == 0) return originArray;
    if (!properties || properties.length == 0) {
      properties = Object.keys(originArray[0]);
    }
    var resultArray = originArray;
    resultArray.map((item) => {
      let reg = /[\u0300-\u036f]/g;
      var itemFound = false;
      properties.map((s) => {
        if (item[s] && ["string", "number"].includes(typeof item[s])) {
          if (
            item[s]
              .toString()
              .toLowerCase()
              .normalize("NFD")
              .replace(reg, "")
              .indexOf(string.toLowerCase().normalize("NFD").replace(reg, "")) >
            -1
          ) {
            itemFound = true;
          }
        }
      });
      item.hidden = !itemFound;
    });
    return resultArray;
  } catch (e) {
    console.log(e);
    return originArray;
  }
}

function getRemainingTime(date, invert = false) {
  const now = moment();
  const diff = Math.abs(now.diff(date));
  let remainingMinutes = parseInt(diff / 1000 / 60);
  let remainingHours = parseInt(remainingMinutes / 60);
  let remainingDays = parseInt(remainingHours / 24);

  let textRemaining = "";

  if (invert) {
    if (remainingMinutes <= 1) {
      textRemaining = "few seconds ago";
    } else {
      textRemaining = `${remainingMinutes} minutes ago`;
    }
    if (remainingHours >= 1) {
      textRemaining = `${remainingHours} hours ago`;
    }
    if (remainingDays >= 1) {
      textRemaining = `${remainingDays} day(s) ago`;
    }
  } else {
    if (remainingMinutes <= 1) {
      textRemaining = "Starts in a few seconds";
    } else {
      textRemaining = `Starts in about ${remainingMinutes} minutes`;
    }
    if (remainingHours >= 1) {
      textRemaining = `Starts in about ${remainingHours} hours`;
    }
    if (remainingDays >= 1) {
      textRemaining = `Starts in about ${remainingDays} day(s)`;
    }
  }
  return textRemaining;
}
function sortCols(columns, colIndex) {
  try {
    return columns.map((r, index) => {
      let order = null;
      let loading = false;
      if (colIndex == index) {
        loading = true;
        order = r.order == null ? "ASC" : r.order == "ASC" ? "DESC" : null;
      }
      let l = {
        ...r,
        order,
        loading,
      };
      return l;
    });
  } catch (e) {
    console.log(e);
    return columns;
  }
}

function youtubeParser(url) {
  // https://www.youtube.com/shorts/5jzvxifYejg
  // https://youtu.be/-MeFJTxGpfU
  // http://www.youtube.com/watch?v=0zM3nApSvMg&feature=feedrec_grec_index
  // http://www.youtube.com/user/IngridMichaelsonVEVO#p/a/u/1/QdK8U-VIH_o
  // http://www.youtube.com/v/0zM3nApSvMg?fs=1&amp;hl=en_US&amp;rel=0
  // http://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s
  // http://www.youtube.com/embed/0zM3nApSvMg?rel=0
  // http://www.youtube.com/watch?v=0zM3nApSvMg
  // http://youtu.be/0zM3nApSvMg
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/|shorts\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  console.log(match);
  return match && match[7].length == 11
    ? `https://youtube.com/watch?v=${match[7]}`
    : false;
}

function toggleModal(id) {
  const button = document.getElementById(`${id}_modal_button`);
  if (button) {
    button?.click();
  }
}

export {
  sortCols,
  dataURLtoFile,
  timeMask,
  currencyMask,
  phoneMask,
  weekdays,
  pad,
  countryPhoneCodes,
  searchString,
  getRemainingTime,
  youtubeParser,
  toggleModal,
};
