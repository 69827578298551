import moment from "moment";
import UserService from "../../services/UserService";
import ListControllerMixin from "../../utils/ListControllerMixin";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      filters: {
        page: 1,
        q: "",
        type: "student,professional",
      },
      columns: [
        {
          name: "Nome",
          sortName: null,
          order: null,
        },
        {
          name: "Email",
          sortName: null,
          order: null,
        },
        {
          name: "Tipo",
          sortName: null,
          order: null,
        },
        {
          name: "Tipo de profissional",
          sortName: null,
          order: null,
        },
        {
          name: "Assinante",
          sortName: null,
          order: null,
        },
        {
          name: "Data de criação",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    fetchPromise() {
      return this.$userService.list({
        ...this.filters,
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
    prepareResponse(data) {
      return data.map((elem) => ({
        ...elem,
        account_type: this.getType(elem.type),
        professionalType:
          UserService.PROFESSIONAL_TYPES[elem.professional_type],
        // has_plan: elem.stripe_id && elem.subs && (elem.subs?.stripe_status == 'trialing' || elem.subs?.stripe_status == 'active')
        subscriptionConfig: this.setupSubscriptionConfig(elem),
      }));
    },
    setupSubscriptionConfig(data) {
      const hasStripeSubs =
        data.subs &&
        (data.subs.stripe_status === "active" ||
          data.subs.stripe_status === "trialing");

      const hasCanceledButActive =
        data.subs &&
        data.subs.stripe_status == "canceled" &&
        moment().isBefore(data.subs.ends_at);

      const hasIAPSubs =
        data.inapp_subs &&
        (data.inapp_subs.plan_status === "paid" ||
          data.inapp_subs.stripe_status === "trial");

      const isPremiumAccount =
        hasIAPSubs || hasStripeSubs || hasCanceledButActive;

      if (isPremiumAccount) {
        if (
          data.subs?.stripe_price ==
          process.env.VUE_APP_BACKOFFICE_STRIPE_PRICE_ID
        ) {
          return {
            label: "Assinante pelo Admin",
            badgeColor: "bg-success-soft",
          };
        }
        return {
          label: "Assinante",
          badgeColor: "bg-success-soft",
        };
      } else {
        return {
          label: "Não assinante",
          badgeColor: "bg-danger-soft",
        };
      }
    },
    getType(type) {
      switch (type) {
        case "student":
          return "Estudante";
        case "professional":
          return "Profissional";
        case "admin":
          return "Administrador";
        default:
          break;
      }
    },
    handleSubscriberFilter(event) {
      if (event.target.checked) {
        this.filters.subscriber = event.target.checked;
      } else {
        delete this.filters.subscriber;
      }
      this.loadingItems = true;
      this.getData();
    },
  },
};
