<template>
  <div id="prescriptions">
    <Navbar current="Receitas" />
    <Sidebar />
    <Container col="col-12">
      <Header
        title="Receitas"
        icon="fa-solid fa-prescription-bottle-medical"
        :count="totalItemsRegistered"
        subtitle="Lista de todas as receitas"
      />
      <div class="card">
        <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState
            v-if="!items.length"
            :totalItemsRegistered="totalItemsRegistered"
            icon="fa-solid fa-prescription-bottle-medical"
            title="Nenhuma receita encontrada"
          >
            <template v-slot:noItemsRegistered></template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table class="table table-sm table-nowrap card-table table-hover">
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <tr v-for="(item, index) in items" v-bind:key="'tr-' + index">
                  <td>{{ item.profissional?.name }}</td>
                  <td>{{ item.patient?.name }}</td>
                  <td>{{ item?.type_label }}</td>
                  <td>
                    <time>{{
                      $moment(item.created_at).format("DD/MM/YYYY")
                    }}</time>
                  </td>
                  <td
                    v-if="item.pdf_url"
                    @click="openPDF(item)"
                    class="cursor-pointer"
                  >
                    <i
                      class="fa-solid fa-file-pdf fa-lg text-primary"
                      data-bs-toggle="tooltip"
                      title="Ver receita"
                    ></i>
                  </td>
                  <td v-else></td>
                  <td class="text-center">
                    <div class="dropdown">
                      <a
                        href="#"
                        class="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa-solid fa-ellipsis text-primary"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end" style="">
                        <span
                          @click="openPDF(item)"
                          class="dropdown-item text-muted cursor-pointer"
                        >
                          Ver receita
                        </span>
                        <hr class="my-2" />
                        <router-link
                          tag="a"
                          :to="`${$paths.patients.detail}/${item.patient_id}`"
                          class="dropdown-item text-muted"
                        >
                          Ver paciente
                        </router-link>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted"
            >mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span
          >
        </div>
      </div>
      <div
        class="row justify-content-center"
        v-if="!loadingItems && moreItemsToLoad"
      >
        <div class="col-2">
          <FormButton
            label="Carregar mais"
            :loading="loadingMoreItems"
            @onClick="loadMore"
          />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import PrescriptionsController from "./PrescriptionsController";
export default PrescriptionsController;
</script>
