<template>
  <div id="patients_detail">
    <Navbar
      current="Detalhe do paciente"
      :link="$paths.patients.list"
      :linkName="'Pacientes'"
    />
    <Sidebar />
    <Container>
      <Header title="Detalhe do Paciente"> </Header>

      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <TextInput :readOnly="true" label="Nome" :value="item.name" />
              </div>
              <div class="col-12 col-md-6">
                <TextInput
                  :readOnly="true"
                  label="Data de criação"
                  :value="item.formatted_created_at"
                />
              </div>
              <div class="col-12 col-md-6">
                <TextInput :readOnly="true" label="CPF" :value="item.cpf" />
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h2 class="text-header mb-0">Prontuário</h2>
          </div>
          <EmptyState
            v-if="item.medical_records_count == 0"
            icon="fa-solid fa-clipboard-user"
            title="Nenhum prontuário encontrado"
            :noMsg="true"
          />
          <div class="table-responsive" v-else>
            <table class="table table-sm table-nowrap card-table table-hover">
              <TableHead :columns="columns" />
              <tbody class="list">
                <tr
                  v-for="(item, index) in item.medical_records"
                  v-bind:key="'tr-' + index"
                >
                  <td>{{ item.patient.name }}</td>
                  <td>{{ item.digital ? "Sim" : "Não" }}</td>
                  <td>
                    <time>{{
                      $moment(item.created_at).format("DD/MM/YYYY")
                    }}</time>
                  </td>
                  <td
                    v-if="item.pdf_url"
                    class="text-end cursor-pointer"
                    @click="openPDF(item)"
                  >
                    <i
                      class="fa-solid fa-lg fa-file-pdf text-primary"
                      data-bs-toggle="tooltip"
                      title="Ver prontuário"
                    ></i>
                  </td>
                  <td v-else></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h2 class="text-header mb-0">Receitas</h2>
          </div>
          <EmptyState
            v-if="item.recipe_count == 0"
            icon="fa-solid
            fa-prescription-bottle-medical"
            title="Nenhuma receita encontrada"
            :noMsg="true"
          />
          <div class="table-responsive" v-else>
            <table class="table table-sm table-nowrap card-table table-hover">
              <TableHead :columns="columns" />
              <tbody class="list">
                <tr
                  v-for="(item, index) in item.recipes"
                  v-bind:key="'tr-' + index"
                >
                  <td>{{ item.patient.name }}</td>
                  <td>{{ item.digital ? "Sim" : "Não" }}</td>
                  <td>
                    <time>{{
                      $moment(item.created_at).format("DD/MM/YYYY")
                    }}</time>
                  </td>
                  <td
                    v-if="item.pdf_url"
                    class="text-end cursor-pointer"
                    @click="openPDF(item)"
                  >
                    <i
                      class="fa-solid fa-lg fa-file-pdf text-primary"
                      data-bs-toggle="tooltip"
                      title="Ver receita"
                    ></i>
                  </td>
                  <td v-else></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import PatientDetailController from "./PatientDetailController";
export default PatientDetailController;
</script>
