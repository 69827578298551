<template>
  <div id="not_found">
    <Navbar />
    <div class="container">
      <div class="row justify-content-center align-items-center height-fix">
        <div class="col-12 col-md-6 my-5">
          <div>
            <!-- Preheading -->
            <h6 class="text-header text-muted mb-0">Error 404</h6>

            <!-- Heading -->
            <h1 class="text-pagetitle mb-3">
              <i class="fa-regular fa-face-sad-tear"></i>
              Página não encontrada
            </h1>

            <!-- Subheading -->
            <p class="text-paragraph mb-4">
              Desculpe, mas a página que você estava procurando não existe ou
              foi movida para outro lugar.
            </p>

            <!-- Button -->
            <router-link
              tag="a"
              :to="$paths.home"
              class="btn btn-lg btn-primary text-action"
            >
              Voltar para a Home
            </router-link>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
<style scoped>
.height-fix {
  height: calc(100vh - 60.5px);
}
</style>
