import { NetworkService } from "./NetworkService";

export default class PrescriptionsService {
  constructor() {
    this.network = new NetworkService();
  }

  list = async (filters) => {
    return await this.network.get(
      this.network.endpoints.prescriptions,
      filters
    );
  };

  show = (id) => {
    return this.network.get(`${this.network.endpoints.prescriptions}/${id}`);
  };

  formatType = (type) => {
    switch (type) {
      case "simple":
        return "Receita simples";
      case "special control":
        return "Receita de controle especial";
      case "sick note":
        return "Atestado médico";
      case "exam request":
        return "Solicitação de exame";
      default:
        return "---";
    }
  };

  static TYPES = {
    SIMPLE: "simple",
    SPECIAL_CONTROL: "special control",
    MEDICAL_CERTIFICATE: "sick note",
    EXAM_SOLICITATION: "exam request",
  };

  types = [
    {
      id: PrescriptionsService.TYPES.SIMPLE,
      name: "Receita simples",
    },
    {
      id: PrescriptionsService.TYPES.SPECIAL_CONTROL,
      name: "Receita de controle especial",
    },
    {
      id: PrescriptionsService.TYPES.MEDICAL_CERTIFICATE,
      name: "Atestado médico",
    },
    {
      id: PrescriptionsService.TYPES.EXAM_SOLICITATION,
      name: "Solicitação de exame",
    },
  ];
}
