<template>
  <div class="container my-5">
    <!-- <div class="row justify-content-center align-items-center height-fix"> -->
    <div class="row justify-content-center align-items-center">
      <div
        v-if="totalItemsRegistered === 0"
        class="col-12 col-xl-4 my-5 text-center"
      >
        <!-- empty state de qdo nao tem nada no banco  -->
        <slot v-if="$slots.noItemsRegistered" name="noItemsRegistered"></slot>
        <div v-else class="text-center">
          <i v-if="icon" :class="icon" class="text-primary fa-2x mb-4"></i>
          <i
            v-else
            class="fa-solid fa-2x text-primary fa-magnifying-glass mb-4"
          ></i>
          <p class="text-subtitle mb-0">Nenhum item no momento</p>
          <p class="text-muted text-paragraph">
            Parece que ainda não há nenhum item cadastrado por aqui.
          </p>
          <slot name="button"></slot>
        </div>
      </div>
      <div class="col-12 col-md-4 my-5" v-else>
        <div class="text-center">
          <i v-if="icon" :class="icon" class="text-primary fa-2x mb-4"></i>
          <i
            v-else
            class="fa-solid fa-2x text-primary fa-magnifying-glass mb-4"
          ></i>
          <p class="text-subtitle mb-0">
            {{ title || "Nenhum item no momento" }}
          </p>
          <p class="text-muted text-paragraph mb-0" v-if="!noMsg">
            {{ msg || "Assegure que os itens foram digitados corretamente" }}
          </p>
          <slot name="button"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Emptystate",
  props: {
    title: String,
    msg: String,
    icon: String,
    totalItemsRegistered: Number,
    noMsg: Boolean,
  },
};
</script>

<style>
.height-fix {
  height: calc(100vh - 450px);
}
</style>


