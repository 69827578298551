import { Modal } from "bootstrap";

export default {
  name: "ConfirmationAlert",
  mounted() {
    this.$eventBus.$on(
      "showConfirmationAlert",
      ({ title, message, confirmCallback, cancelCallback }) => {
        this.$set(this, "confirmCallback", confirmCallback);
        this.$set(this, "cancelCallback", cancelCallback);
        this.$set(this, "message", message);
        this.$set(this, "title", title);
        this.confirmAlertModal = new Modal(
          document.getElementById("confirmAlertModal"),
          {
            backdrop: "static",
            keyboard: false,
          }
        );
        this.confirmAlertModal.toggle();
      }
    );
  },
  data() {
    return {
      confirmAlertModal: null,
      confirmCallback: () => {},
      cancelCallback: () => {},
      title: null,
      message: null,
      loading: false,
    };
  },
  methods: {
    alertClosed() {
      this.cancelAction();
    },
    async confirmAction() {
      try {
        this.loading = true;
        await this.confirmCallback();
        this.confirmAlertModal.toggle();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    cancelAction() {
      if (this.cancelCallback) {
        this.cancelCallback();
      }
      this.confirmAlertModal.toggle();
    },
  },
  destroyed() {},
};
