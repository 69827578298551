export default {
    data() {
        return {
            text: '',
            settingsInfo: {
                key: '',
                title: '',
            }
        }
    },
    mounted() {
        this.setupSettings()
    },
    methods: {
        setupSettings() {
            this.loadingItems = true
            let k = this.$route.params.key
            switch (k) {
                case 'termos-de-uso':
                    this.$set(this, 'settingsInfo', {
                        key: 'terms_of_use',
                        title: 'Termos de Uso'
                    })
                    break;
                case 'politica-de-privacidade':
                    this.$set(this, 'settingsInfo', {
                        key: 'privacy_policy',
                        title: 'Política de privacidade'
                    })
                    break;
                case 'ajuda':
                    this.$set(this, 'settingsInfo', {
                        key: 'help',
                        title: 'Ajuda'
                    })
                    break;

                default: this.$router.push({ path: this.$paths.not_found })
            }
            this.getData()
        },
        async getData() {
            try {
                const res = await this.$userService.settings(this.settingsInfo.key)
                this.$set(this, 'text', res.data.value)
            } catch (e) {
                console.log(e)
            } finally {
                this.loadingItems = false
            }
        },
    }
}