<template>
  <div id="admins_password_update">
    <Navbar
      current="Alterar senha"
      :link="$paths.admins.list"
      :sublink="`${$paths.admins.detail}/${id}`"
      :linkName="'Administradores'"
      :sublinkName="'Detalhes do administrador'"
    />
    <Sidebar />
    <Container>
      <Header title="Alterar Senha" />
      <div class="card">
        <div class="card-body">
          <h2 class="text-header">Informações</h2>
          <form>
            <PasswordInput
              label="Senha"
              :model="$v.form.old_password"
              placeholder="Senha atual"
            />
            <PasswordInput
              label="Nova senha"
              :model="$v.form.password"
              placeholder="Mínimo de 8 caracteres"
            />
            <PasswordInput
              label="Confirme a senha"
              :model="$v.form.passwordConfirmation"
              placeholder="Confirme sua senha"
            />
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <FormButton
              label="Alterar senha"
              :loading="submitting"
              :disabled="$v.$invalid"
              @onClick="submit"
            />
          </form>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import AdminPasswordUpdateController from "./AdminPasswordUpdateController";
import Header from "../../../components/Header.vue";
export default AdminPasswordUpdateController;
</script>
<style scoped>
</style>
