<template>
	<div id="reports">
		<Navbar current="Denúncias" />
		<Sidebar />
		<Container col="col-12">
			<Header
				:isSubHeader="false"
				:iconClass="'fas fa-explosion text-primary'"
				title="Crashes"
				subtitle="Crashes dos apps"
				:totalCount="totalItemsRegistered"
			>
			</Header>
			<div class="card">
				<SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
				<LoadingContainer v-if="loadingItems" />
				<div v-else>
					<EmptyState v-if="!items.length" :totalItemsRegistered="totalItemsRegistered">
						<template v-slot:noItemsRegistered>
							<span class="fa-3x fa-solid fa-explosion text-black-50 mb-2"></span>
							<p>Nenhum crash</p>
						</template>
					</EmptyState>
					<div v-else class="table-responsive">
						<table
							class="table table-sm table-nowrap card-table table-hover cursor-pointer"
						>
							<TableHead :columns="columns" v-on:sort="sort" />
							<tbody class="list">
								<router-link
									tag="tr"
									v-for="(item, index) in items"
									v-bind:key="'tr-' + index"
									:to="`${$paths.crashes.detail}/${item.id}`"
								>
									<td>
										<span
											v-if="item.os === 'ios'"
											class="fab fa-apple fa-2x text-black"
										></span>
										<span
											v-else
											class="fab fa-android fa-2x text-android"
										></span>
									</td>
									<td>
										{{ item.formatted_message }}
									</td>
									<td class="text-end">
										<time>{{
											$moment(item.created_at).format("DD/MM/YYYY[ - ]HH:mm")
										}}</time>
									</td>
								</router-link>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<!-- <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted"
            >mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span
          >
        </div>
      </div>
      <div
        class="row justify-content-center"
        v-if="!loadingItems && moreItemsToLoad"
      >
        <div class="col-2">
          <FormButton
            label="Carregar mais"
            :loading="loadingMoreItems"
            @onClick="loadMore"
          />
        </div>
      </div> -->
		</Container>
	</div>
</template>
<script>
	import CrashesController from "./CrashesController"
	export default CrashesController
</script>
