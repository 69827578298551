import ListControllerMixin from "../../utils/ListControllerMixin";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      filters: {
        page: 1,
        q: '',
        type: 'admin'
      },
      columns: [
        {
          name: 'Nome',
          sortName: null,
          order: null
        },
        {
          name: 'Email',
          sortName: null,
          order: null
        },
        {
          name: 'Data',
          sortName: null,
          order: null
        },
      ],
    };
  },
  methods: {
    fetchPromise() {
      return this.$userService.list({
        ...this.filters,
        orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
      })
    }
  }
};