<template>
  <div id="calculators">
    <Navbar current="Calculadora" />
    <Sidebar />
    <Container col="col-12">
      <Header
        title="Calculadora"
        icon="fa-solid fa-calculator"
        :count="totalItemsRegistered"
        subtitle="Todos os tipos de calculos médicos."
      />
      <div class="card">
        <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState
            v-if="!items.length"
            :totalItemsRegistered="totalItemsRegistered"
            icon="fa-solid fa-calculator"
            title="Nenhuma calculadora encontrada"
          >
            <template v-slot:noItemsRegistered></template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table
              class="table table-sm table-nowrap card-table table-hover cursor-pointer"
            >
              <TableHead :columns="columns" v-on:sort="sort" />

              <tbody class="list">
                <tr v-for="(item, index) in items" v-bind:key="'tr-' + index">
                  <td>{{ item.name }}</td>
                  <td class="text-end">
                    <time>{{
                      $moment(item.created_at).format("DD/MM/YYYY")
                    }}</time>
                  </td>
                  <td class="text-end">
                    <button
                      class="btn btn-sm btn-outline-primary"
                      @click="handleEdit(item)"
                    >
                      <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted"
            >mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span
          >
        </div>
      </div>
      <div
        class="row justify-content-center"
        v-if="!loadingItems && moreItemsToLoad"
      >
        <div class="col-2">
          <FormButton
            label="Carregar mais"
            :loading="loadingMoreItems"
            @onClick="loadMore"
          />
        </div>
      </div>
    </Container>
    <ModalComponent id="calculator-update" title="Editar calculadora">
      <template v-slot:body>
        <div class="row">
          <div class="col-12">
            <TextInput
              label="Nome"
              :model="$v.form.name"
              type="text"
              placeholder="Informe um nome"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <FormButton
          label="Concluir"
          :loading="submitting"
          :disabled="$v.$invalid"
          @onClick="handleUpdate"
        />
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import CalculatorsController from "./CalculatorsController";
export default CalculatorsController;
</script>
