export default {
  name: "ContentDetail",
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      item: {},
      id: this.$route.params.id,
      parentCategory: null,
      loadingItems: false,
      copied: false,
    };
  },
  methods: {
    async getData() {
      try {
        const res = await this.$contentsService.show(this.id);
        let data = res.data;
        this.$set(this, "item", data);
        this.getContentCategory(res.data.content_category_id);
      } catch (e) {
        console.log(e);
      }
    },
    async getRefContent(id) {
      try {
        let res = await this.$contentsService.show(id);
        this.$set(this, "parentCategory", res.data);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingItems = false;
      }
    },
    async getContentCategory(id) {
      try {
        let res = await this.$contentsService.showCategory(id);
        res.data.type = "category";
        this.$set(this, "parentCategory", res.data);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingItems = false;
      }
    },
    handleDelete() {
      this.$eventBus.$emit("showConfirmationAlert", {
        message: "Deseja excluir esse conteúdo permanentemente?",
        confirmCallback: async () => {
          try {
            this.$contentsService.delete(this.id);
            this.$eventBus.$emit("showToast", {
              type: "danger",
              title: "Conteúdo excluído",
              msg: `Conteúdo excluído com sucesso`,
            });

            let path = this.parentCategory?.count_subcategory
              ? `${this.$paths.contents.subcategories.list}/${this.parentCategory?.id}`
              : `${this.$paths.contents.list}/${this.parentCategory?.id}`;

            this.$router.push({ path: path });
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
    copyURL() {
      this.$set(this, "copied", true);
      setTimeout(() => {
        this.$set(this, "copied", false);
      }, 3000);
      let text = window.location.href.split(
        process.env.VUE_APP_BACKOFFICE_BASE_URL
      );
      navigator.clipboard.writeText(text[1]);
    },
  },
};
