import { required } from "vuelidate/lib/validators";

export default {
  name: "Medicines",
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      item: {},
      id: this.$route.params.id,
      loadingItems: false,
      copied: false,
      isEditing: false,
      submitting: false,
      loadingDelete: false,
      currentItemId: null,
      form: {
        names: [],
      },
    };
  },
  validations: {
    form: {
      names: {
        $each: {
          name: { required },
        },
      },
    },
  },
  methods: {
    async getData() {
      try {
        const res = await this.$medicinesService.show(this.id);
        let data = res.data;
        this.$set(this.form, "names", res.data.names);
        this.$set(this, "item", data);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingItems = false;
        this.$set(this, "loadingDelete", false);
      }
    },
    handleDelete() {
      this.$eventBus.$emit("showConfirmationAlert", {
        message:
          "Deseja remover esse medicamento permanentemente (Os nomes comerciais relacionados a esse medicamento também serão removidos) ?",
        confirmCallback: async () => {
          try {
            // this.$set(this, `loadingDelete`, true);
            this.$medicinesService.delete(this.id);
            this.$eventBus.$emit("showToast", {
              type: "danger",
              title: "Medicamento excluído",
              msg: `Medicamento excluído com sucesso`,
            });
            this.$router.push({ path: `${this.$paths.medicines.list}` });
          } catch (e) {
            console.log(e);
          } finally {
            // this.$set(this, `loadingDelete`, false);
          }
        },
      });
    },
    copyURL() {
      this.$set(this, "copied", true);
      setTimeout(() => {
        this.$set(this, "copied", false);
      }, 3000);
      let text = window.location.href.split(
        process.env.VUE_APP_BACKOFFICE_BASE_URL
      );
      navigator.clipboard.writeText(text[1]);
    },
    editItems() {
      if (this.isEditing) {
        this.$set(this.form, "names", this.item.names);
      }
      this.isEditing = !this.isEditing;
    },
    addItem() {
      this.$set(this.form, "names", [...this.form.names, { name: "" }]);
    },
    removeItem(index) {
      this.$eventBus.$emit("showConfirmationAlert", {
        title: "Remover nome comercial",
        message: "Deseja remover esse nome comercial do medicamento?",
        confirmCallback: async () => {
          try {
            this.$set(this, "loadingDelete", true);
            let items = [...this.form.names];
            this.currentItemId = items[index]?.id;
            let res = await this.$medicinesService.deleteMedicineName(
              items[index]?.id
            );
            if (res.status) {
              items.splice(index, 1);
              this.getData();
            }
          } catch (error) {
            console.log(error);
          }
        },
      });
    },
    async updateNames() {
      try {
        this.submitting = true;
        this.isEditing = !this.isEditing;
        let params = {
          names: this.form.names.map((elem) => ({ name: elem.name })),
        };
        // await this.$medicinesService.updateMedicineNames(params);
        await this.$medicinesService.update(this.item?.id, params);
        this.getData();
      } catch (error) {
        console.log(error);
      } finally {
        this.submitting = false;
      }
    },
  },
};
