<template>
  <div id="settings">
    <Navbar
      current="Contratos"
      :link="settingsInfo.path"
      :linkName="settingsInfo.title"
    />
    <Sidebar />
    <Container col="col-10">
      <Header
        :title="settingsInfo.title"
        @btnAction="view"
        btnLabel="Visualizar"
        :tabs="tabs"
        @tabClicked="tabClicked"
        icon="fa-solid fa-file-signature"
      />
      <div class="card">
        <div class="card-body">
          <LoadingContainer v-if="loadingItems" />
          <div v-else>
            <div class="mb-4">
              <EditorInput :model="$v.text" style="height: calc(50vh)" />
            </div>
            <div class="text-center mt-6" v-if="errorMessage">
              <span class="text-danger">{{ errorMessage }}</span>
            </div>
            <FormButton
              label="Salvar"
              :loading="submitting"
              :disabled="$v.$invalid"
              class="mt-6"
              @onClick="submit"
            />
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import SettingsEditController from "./SettingsEditController";
export default SettingsEditController;
</script>
