<template>
  <div id="add_content">
    <Navbar :current="'Editar conteúdo'" :link="$paths.contents.categories.list" :linkName="'Conteúdos'"
      :sublink="`${$paths.contents.detail}/${id}`" :sublinkName="'Detalhe do conteúdo'" />
    <Sidebar />
    <Container>
      <Header title="Editar conteúdo" />
      <div class="card">
        <LoadingContainer v-if="loading" />
        <div v-else>
          <div class="card-body">
            <h2 class="text-header">Local de criação</h2>
            <TextInput :readOnly="true" label="Local" :value="parentCategory?.name">
              <template v-slot:labelContentEnd>
                <router-link :to="`${$paths.contents.list}/${parentCategory?.id}`">Ver itens</router-link>
              </template>
            </TextInput>
          </div>

          <hr class="my-0" />

          <div class="card-body">
            <h2 class="text-header">Título</h2>

            <TextInput label="Título" :model="$v.form.name" placeholder="Insira o titulo do conteúdo" />

            <!-- <TextAreaInput
              :model="$v.form.description"
              label="Descrição"
              placeholder="Insira a descrição do conteúdo"
              class="mb-3"
            /> -->

            <label class="form-label text-muted">Conteúdo</label>
            <div class="form-check form-switch d-flex ps-0">
              <label class="form-check-label" for="isPremium">Conteúdo exclusivo para assinantes
              </label>
              <input v-model="$v.form.only_subscribers.$model" class="form-check-input ms-3" type="checkbox" role="switch"
                id="isPremium" />
            </div>
          </div>

          <hr class="my-0" />

          <!-- ADD CONTENT CONTAINER-->
          <div class="card-body">
            <h2 class="text-header">Conteúdo</h2>

            <!-- CONTENTS -->
            <div v-for="(item, index) in $v.form.items.$each.$iter" v-bind:key="'text_' + index">
              <div class="row">
                <div class="col-12 col-md-7">
                  <p class="text-subtitle">
                    <i class="text-primary" :class="getTypeIcon(item.type.$model)"></i>
                    {{ getTypeLabel(item.type.$model) }}
                  </p>
                </div>
                <div class="col-12 col-md-5 text-end">
                  <button v-if="item.id.$model && item.update.$model" class="btn btn-sm btn-success me-3"
                    @click.prevent="updateContent(index, item.id.$model)">
                    <span v-if="submittingItem" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    Salvar
                  </button>

                  <button v-if="item.id.$model" class="btn btn-sm btn-info me-3" @click.prevent="editContent(index)">
                    {{ item.update.$model ? "Cancelar" : "Editar" }}
                  </button>

                  <button class="btn btn-sm btn-del" @click.prevent="removeContent(index, item.id.$model)">
                    Remover
                  </button>
                </div>
              </div>

              <!-- SELECT TAB AND SORT BUTTON -->
              <div class="row">
                <div class="col">
                  <SelectInput :readOnly="item.id.$model && !item.update.$model" label="Aba" :hasDefaultValue="true"
                    propertyForText="name" @selectChanged="($event) => selectTab($event, index)" :model="item.tab_id"
                    :options="tabs" />
                </div>
                <div class="col-auto">
                  <button class="btn btn-outline-primary btn-lg text-subtitle" style="margin-top: 28px"
                    data-bs-toggle="modal" data-bs-target="#sort_items" @click="
                      setupSortItems(item.tab_id.$model, item.fakeId.$model)
                      ">
                    Alterar ordem
                    <i class="fa-solid fa-arrow-up-wide-short"></i>
                  </button>
                </div>
              </div>

              <!-- TEXT INPUT -->
              <TextInput :readOnly="item.id.$model && !item.update.$model" :value="item.name.$model" label="Título"
                :model="item.name" placeholder="Insira o titulo do conteúdo" />

              <!-- DESCRIPTION INPUT -->
              <TextAreaInput v-if="item.type.$model != 'html'" :readOnly="item.id.$model && !item.update.$model"
                :value="item.description.$model" label="Descrição" placeholder="Insira a descrição do conteúdo"
                :model="item.description" class="mb-4" />

              <!-- CUSTOM DESCRIPTION INPUT -->
              <label class="form-label text-muted" v-if="item.type.$model == 'html'">Descrição</label>
              <!-- <EditorInput v-if="item.type.$model == 'html'" :readOnly="item.id.$model && !item.update.$model"
              :model="item.description" style="height: calc(30vh)" /> -->
              <template v-if="item.type.$model == 'html'">
                <div class="ql-editor bg-light border" style="max-height: calc(30vh);" v-html="item.description.$model"
                  v-if="item.id.$model && !item.update.$model"></div>
                <EditorInput v-else :model="item.description" style="height: calc(30vh)" />
              </template>

              <!-- VIDEO INPUT -->
              <div v-if="item.type.$model == 'video'">
                <label class="form-label text-muted"> Video </label>
                <div class="input-group mb-3">
                  <span class="input-group-text">
                    <i class="fa-solid fa-photo-video"></i>
                  </span>

                  <input v-if="item.id.$model && !item.update.$model" readonly="true" :value="item.url.$model"
                    class="form-control form-control-lg" />

                  <input v-else @input="setIframe($event, index)" type="text" class="form-control form-control-lg"
                    v-model="item.url.$model" placeholder="www.youtube.com.br/8dS912kdsa9" />
                </div>

                <iframe class="mb-2" v-if="item.url.$model" width="100%" height="500" :src="item.url.$model"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen style="border-radius: 15px"></iframe>

                <div class="form-check form-switch d-flex ps-0">
                  <label class="form-check-label" for="isShort">Shorts </label>

                  <input :disabled="item.id.$model && !item.update.$model" v-model="item.short.$model"
                    class="form-check-input ms-3" type="checkbox" role="switch" id="isShort" />
                </div>
              </div>

              <!-- IMAGE INPUT -->
              <div v-if="item.type.$model == 'image'">
                <label class="form-label text-muted">Imagem</label>
                <div v-if="item.id.$model && !item.update.$model">
                  <img :src="`${$baseImageUrl}${item.image.$model}`" class="img-thumbnail" />
                </div>
                <ImageCropper v-else v-on:cropEnd="cropEnd($event, index)" :index="index" />
              </div>

              <!-- CALCULATOR INPUT -->
              <div v-if="item.type.$model == 'calculator'">
                <SelectInput label="Formula" placeholder="Selecione a formula" @selectChanged="($event) => selectCalculatorType($event, index)
                  " :readOnly="item.id.$model && !item.update.$model" propertyForText="name" propertyForValue="key"
                  :model="item.calculator_type" :options="calculatorTypes" />
              </div>

              <!-- SCORE INPUT -->
              <div v-if="item.type.$model == 'score'">
                <SelectInput label="Formula" placeholder="Selecione a formula" :model="item.score_id" :options="[]" />
              </div>

              <hr class="my-5" />
            </div>

            <!-- ADD CONTENT BUTTONS-->
            <div class="border border-primary p-4 rounded">
              <p class="text-subtitle">Adicionar conteúdo</p>
              <p>Selecione abaixo um novo tipo de conteúdo para adicionar</p>
              <div class="d-flex flex-wrap">
                <div v-for="(item, index) in contentTypes" :key="index" class="add-content me-3 cursor-pointer"
                  @click="addContent(item.type)">
                  <div class="bg-primary-soft add-content-card d-flex justify-content-center align-items-center mb-2">
                    <i class="text-primary fa-3x" :class="item.icon"></i>
                  </div>
                  <p class="text-subtitle text-primary text-center">
                    {{ item.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr class="my-0" />

          <div class="card-body">
            <FormButton label="Salvar" :loading="submitting" @onClick="submit" :disabled="$v.$invalid" />
          </div>
        </div>
      </div>
    </Container>
    <ModalComponent id="sort_items" title="Alterar ordem">
      <template v-slot:body>
        <ul class="nav nav-tabs nav-overflow mb-4">
          <li class="nav-item" v-for="(item, index) of tabs" v-bind:key="index">
            <a href="#" @click.prevent="setupSortItems(item.id)" class="nav-link text-subtitle pt-0"
              :class="{ active: item.active, 'text-primary': item.active }">
              {{ item.name }}
            </a>
          </li>
        </ul>

        <draggable class="list-group list-group-sm mb-4" v-model="sortItems[currentTab?.name]" @start="drag = true"
          @end="drag = false" @change="changeOrder">
          <li v-for="(element, index) in sortItems[currentTab?.name]" :key="element.id"
            class="list-group-item d-flex justify-content-between align-items-center cursor-move">
            <div>
              <strong> {{ index }}º </strong>
              <strong v-if="element.fakeId == currentItem">
                {{ element.name }} - Selecionado
              </strong>
              <span v-else>{{ element.name }} </span>
            </div>
            <i class="fa-solid fa-bars text-muted"></i>
          </li>
        </draggable>
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary align-self-end" @click="updateContentItems">
            <span v-if="submittingOrder" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="text-action"> Concluir </span>
          </button>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>
      
<script>
import EditContentController from "./EditContentController";
export default EditContentController;
</script>
  
<style>
.add-content {
  width: 105px;
}

.add-content-card {
  width: 105px;
  height: 105px;
  border-radius: 10px;
}
</style>