import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userSession: JSON.parse(localStorage.getItem(process.env.VUE_APP_API_SESSION_KEY)),
    token: null
  },
  mutations: {
    saveSession(state, newSession) {
      state.userSession = newSession
      localStorage.setItem(process.env.VUE_APP_API_SESSION_KEY, JSON.stringify(newSession))
    },
    saveToken(state, token) {
      state.token = token
      localStorage.setItem(process.env.VUE_APP_API_TOKEN_KEY, token)
    },
    clearSession(state) {
      state.userSession = null
      state.token = null
      localStorage.removeItem('token')
      localStorage.removeItem(process.env.VUE_APP_API_SESSION_KEY)
    }
  },
  actions: {
    syncSessionWithApi: async ({ commit }, data, { token } = { token: null }) => {
      commit('saveSession', data)
    },
    saveSession: async ({ commit }, data) => {
      commit('saveSession', data.data)
      commit('saveToken', data.access_token)
    }
  },
  modules: {
  }
})
