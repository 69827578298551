import { email, required, sameAs, minLength } from 'vuelidate/lib/validators';
export default {
  name: 'AddAdmins',
  mounted() {
  },
  data() {
    return {
      errorMessage: '',
      submitting: false,
      form: {
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        type: 'admin'
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8)
      },
      passwordConfirmation: {
        required,
        sameAs: sameAs('password'),
        minLength: minLength(8)
      },
    }
  },
  methods: {
    async submit() {
      try {
        this.submitting = true
        this.errorMessage = ''
        const values = { ...this.form }
        delete (values.passwordConfirmation)
        const res = await this.$userService.create(values)

        this.$eventBus.$emit('showToast', { title: 'Admin criado', msg: `Admin adicionado com sucesso` })
        this.$router.push({ path: `${this.$paths.admins.list}` })
      } catch (e) {
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    }

  }
};