<template>
  <nav class="navbar navbar-expand navbar-light d-none d-md-flex" id="topbar">
    <div class="container-fluid">
      <nav aria-label="breadcrumb" class="me-auto ms-4">
        <ol class="breadcrumb">
          <router-link
            v-if="link"
            tag="li"
            :to="link"
            class="breadcrumb-item d-flex align-items-center text-subtitle"
          >
            <a class="text-dark">{{ linkName }}</a>
          </router-link>
          <router-link
            v-if="sublink"
            tag="li"
            :to="sublink"
            class="breadcrumb-item d-flex align-items-center text-subtitle"
          >
            <a class="text-dark">{{ sublinkName }}</a>
          </router-link>
          <li
            v-if="link || sublink"
            class="breadcrumb-item active d-flex align-items-center"
            aria-current="page"
          >
            {{ current }}
          </li>
          <li
            v-else
            class="
              breadcrumb-item
              active
              d-flex
              align-items-center
              text-subtitle text-dark
            "
            aria-current="page"
          >
            {{ current }}
          </li>
        </ol>
      </nav>

      <div class="navbar-user">
        <div
          class="dropdown cursor-pointer d-flex align-items-center"
          @mouseover="avatarOver"
          @mouseleave="avatarLeave"
        >
          <span class="me-3 text-subtitle text-muted">{{
            userSession?.name
          }}</span>
          <span class="dropdown-toggle text-primary">
            <i class="fa-solid fa-circle-user display-4"></i>
          </span>
          <div class="dropdown-menu dropdown-menu-end" id="navbar_dropdown">
            <router-link
              class="dropdown-item text-dark"
              tag="a"
              :to="`${$paths.admins.detail}/${userSession?.id}`"
            >
              Minha conta
            </router-link>
            <hr class="dropdown-divider py-0" />
            <span @click="logout" class="dropdown-item text-muted">Sair</span>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import $ from "jquery";
export default {
  name: "Navbar",
  props: {
    link: String,
    linkName: String,
    sublink: String,
    sublinkName: String,
    current: String,
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  methods: {
    async logout() {
      try {
        await this.$userService.logout();
      } catch (error) {
        console.log("ERRO NO LOGOUT", error);
      } finally {
        this.$router.push(this.$paths.login);
      }
    },
    avatarOver() {
      $("#navbar_dropdown").addClass("show");
      $("#navbar_dropdown").attr("data-bs-popper", true);
    },
    avatarLeave() {
      $("#navbar_dropdown").removeClass("show");
      $("#navbar_dropdown").removeAttr("data-bs-popper");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar {
  padding-left: 250px !important;
}
</style>
