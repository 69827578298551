<template>
  <div class="header mt-md-5" id="header">
    <div class="header-body">
      <div class="row align-items-end">
        <div class="col">
          <div class="d-flex align-items-center">
            <i v-if="icon" class="fa-2x me-3 text-primary" :class="icon" />
            <h1 class="header-title text-pagetitle">
              {{ title }}
            </h1>
            <span
              v-if="count"
              class="badge bg-primary-soft ms-3 display-6 align-self-center"
            >
              {{ count }}
            </span>
          </div>
          <h5 v-if="subtitle" class="header-pretitle text-paragraph mb-0">
            {{ subtitle }}
            <span class="featured-subtitle">{{ featuredSubtitle }}</span>
          </h5>
        </div>
        <div class="col-auto">
          <slot name="button"></slot>
        </div>
        <div v-if="btnLabel" class="col-auto">
          <router-link
            v-if="btnLink"
            :to="btnLink"
            tag="a"
            class="btn btn-primary lift"
          >
            <i v-if="btnIcon" :class="btnIcon" class="me-1"></i>
            {{ btnLabel }}
          </router-link>

          <button
            v-else
            @click="handleBtnAction()"
            class="btn btn-primary lift"
          >
            {{ btnLabel }}
            <i v-if="btnIcon" :class="btnIcon" class="ms-1"></i>
          </button>
        </div>
      </div>

      <div class="row align-items-center" v-if="tabs">
        <div class="col">
          <ul class="nav nav-tabs nav-overflow header-tabs">
            <li
              class="nav-item"
              v-for="(item, index) of tabs"
              v-bind:key="index"
            >
              <a
                href="#"
                @click.prevent="handleTabClicked(item.id)"
                class="nav-link text-subtitle"
                :class="{ active: item.active }"
              >
                {{ item.value }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    icon: String,
    title: String,
    image: String,
    subtitle: String,
    featuredSubtitle: String,
    count: Number,
    btnLabel: String,
    btnLink: String,
    btnAction: Function,
    tabClicked: Function,
    btnIcon: String,
    tabs: Array,
    col: {
      type: String,
      default: "col-10",
    },
  },
  methods: {
    handleBtnAction() {
      this.$emit("btnAction");
    },
    handleTabClicked(id) {
      console.log(id);
      this.$emit("tabClicked", id);
    },
  },
};
</script>

<style>
.featured-subtitle {
  color: #000000;
  font-weight: 700;
}
</style>