export default {
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      item: {},
      id: this.$route.params.id,
      loadingItems: false,
      columns: [
        {
          name: 'Paciente',
          sortName: null,
          order: null
        },
        {
          name: 'Assinatura digital',
          sortName: null,
          order: null
        },
        {
          name: 'Data',
          sortName: null,
          order: null
        },
        {
          name: 'PDF',
          sortName: null,
          order: null
        },
      ],
    };
  },
  methods: {
    async getData() {
      try {
        const res = await this.$patientsService.show(this.id)
        let data = res.data
        data.formatted_created_at = this.$moment(data.created_at).format('DD/MM/YYYY')
        this.$set(this, 'item', data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingItems = false
      }
    },
    openPDF(item) {
      if (item.pdf_url) {
        window.open(`${process.env.VUE_APP_IMG_BASE_URL}${item.pdf_url}`, '_blank');
      }
    }
  }
}