import ListControllerMixin from "@/utils/ListControllerMixin";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      type: '',
      columns: [
        {
          name: 'OS',
          sortName: null,
          order: null
        },
        {
          name: 'Exception',
          sortName: null,
          order: null
        },
        {
          name: 'Data',
          sortName: null,
          order: null
        },
      ],
    };
  },
  methods: {
    prepareResponse(data) {
      return data.map(elem => ({
        ...elem,
        formatted_message: JSON.parse(elem.message).error?.message.limitString(100)
        // statusObject: this.$tasksService.getTaskStatus(elem.status),
        // pillarObject: this.$missionsService.getMissionPillar(elem.pillar),
      }))
    },
    fetchPromise() {
      return this.$userService.listCrashes({
        ...this.filters,
        orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
      })
    }
  }
};