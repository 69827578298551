<template>
  <div id="ai_learning">
    <Navbar current="Arquivos IA" />
    <Sidebar />
    <Container col="col-12">
      <Header
        title="Arquivos IA"
        icon="fa-solid fa-brain"
        :count="totalItemsRegistered"
        @btnAction="addFile"
        btnLabel="Adicionar"
        btnIcon="fa-solid fa-plus-circle"
      />

      <div class="card">
        <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState
            v-if="!items.length"
            :totalItemsRegistered="totalItemsRegistered"
            icon="fa-solid fa-brain"
            title="Nenhum item encontrado"
          >
            <template v-slot:noItemsRegistered></template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table class="table table-sm table-nowrap card-table">
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <tr v-for="(item, index) in items" :key="'tr-' + index">
                  <td width="100%">{{ item.title }}</td>
                  <td>
                    <a href="" @click.prevent="openPDF(item)">
                      {{ item.fileName }}
                    </a>
                  </td>
                  <td>
                    <span class="badge" :class="item.statusColor">
                      {{ item.status }}
                    </span>
                  </td>
                  <td>
                    <time>{{
                      $moment(item.created_at).format("DD/MM/YYYY")
                    }}</time>
                  </td>

                  <!-- <td width="5%" class="text-end">
                    <button
                      class="btn btn-sm btn-primary"
                      @click="handleDelete(item)"
                    >
                      <i class="fa-solid fa-file-pdf"></i>
                    </button>
                  </td> -->
                  <!-- <td width="5%" class="text-end">
                    <button
                      class="btn btn-sm btn-del"
                      @click="handleDelete(item)"
                    >
                      <i class="fa-solid fa-trash-alt"></i>
                    </button>
                  </td> -->

                  <td class="text-end">
                    <div class="dropdown">
                      <a
                        href="#"
                        class="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa-solid fa-ellipsis text-primary"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end" style="">
                        <span
                          @click="openPDF(item)"
                          class="dropdown-item text-muted text-paragraph cursor-pointer"
                        >
                          <i
                            class="fa-solid fa-eye text-center"
                            style="width: 20px"
                          ></i>
                          Visualizar arquivo
                        </span>
                        <hr class="my-2" />
                        <span
                          @click="handleDelete(item)"
                          class="dropdown-item text-danger text-paragraph cursor-pointer"
                        >
                          <i
                            class="fa-solid fa-trash-alt text-center"
                            style="width: 20px"
                          ></i>
                          Remover arquivo
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted"
            >mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span
          >
        </div>
      </div>
      <div
        class="row justify-content-center"
        v-if="!loadingItems && moreItemsToLoad"
      >
        <div class="col-2">
          <FormButton
            label="Carregar mais"
            :loading="loadingMoreItems"
            @onClick="loadMore"
          />
        </div>
      </div>
    </Container>
    <ModalComponent id="add_file_modal" title="Adicionar arquivo">
      <template v-slot:body>
        <TextInput
          label="Título"
          :model="$v.form.title"
          type="text"
          placeholder="Informe o título"
        />

        <!-- <ImageCropper v-on:cropEnd="fileSelected($event)" accept=".pdf" /> -->
        <FileUploader :accept="'.pdf'" @fileUploaded="fileSelected($event)" />

        <p class="text-label form-label text-center text-danger">
          {{ errorMessage }}
        </p>

        <FormButton
          label="Concluir"
          :loading="submitting"
          :disabled="$v.$invalid"
          @onClick="handleAddFile"
        />
      </template>
    </ModalComponent>
  </div>
</template>
<script>
import AIFilesController from "./AIFilesController";
export default AIFilesController;
</script>
