import { email, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';


export default {
  name: 'Login',
  mounted() {
    this.$store.commit('clearSession')
  },
  data() {
    return {
      errorMessage: '',
      submitting: false,
      form: {
        email: '',
        password: '',
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    }
  },
  methods: {
    ...mapActions([
      'saveSession',
    ]),
    async login() {
      this.errorMessage = ''
      this.submitting = true
      try {
        const res = await this.$userService.login({ ...this.form })
        await this.saveSession(res)
        this.$router.push(this.$paths.home)
      } catch (e) {
        console.log(e)
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    }
  }
};