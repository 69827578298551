import { NetworkService } from "./NetworkService";

export default class PatientsService {
  constructor() {
    this.network = new NetworkService();
  }
  create = (params) => {
    return this.network.post(this.network.endpoints.patients, params);
  };
  list = (params) => {
    return this.network.get(this.network.endpoints.patients, params);
  };
  show = (id) => {
    return this.network.get(`${this.network.endpoints.patients}/${id}`);
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.patients}/${id}`);
  };
  update = (id, params) => {
    return this.network.put(`${this.network.endpoints.patients}/${id}`, params);
  };
}
