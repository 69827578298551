<template>
  <div id="add-medicines">
    <Navbar v-if="id" :current="'Editar medicamento'" :link="$paths.medicines.list" :linkName="'Medicamentos'"
      :sublink="`${$paths.medicines.detail}/${id}`" :sublinkName="'Detalhe do medicamento'" />
    <Navbar v-else :current="'Novo medicamento'" :link="$paths.medicines.list" :linkName="'Medicamentos'" />
    <Sidebar />
    <Container>
      <Header :title="id ? 'Editar medicamento' : 'Novo medicamento'" />

      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <form>
          <div class="card">
            <div class="card-body">
              <h2 class="text-header">Informações do medicamento</h2>
              <div class="row">
                <div class="col-12">
                  <label class="text-subtitle mb-3">Substancia</label>
                  <TextInput :model="$v.form.compound" type="text" placeholder="Substancia" />
                </div>
              </div>
            </div>

            <hr class="my-0" />

            <!-- <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <label class="text-subtitle mb-3">Nome comercial</label>
                  <TextInput label="Nomes comerciais de referência" :model="$v.form.name" type="text"
                    placeholder="Nomes comerciais de referência" />
                  <TextInput label="Nomes comerciais similares" :model="$v.form.similar_names" type="text"
                    placeholder="Nomes comerciais similares" />
                </div>
              </div>
            </div> -->

            <hr class="my-0" />

            <div class="card-body">
              <div class="row">
                <!-- // TODO: Modularizar esse select doidão aqui -->
                <div class="col-12 mb-4">
                  <label class="text-subtitle mb-3">Classe Terapêutica</label>
                  <div class="form-group mb-0">
                    <input id="categoryInput" class="form-control form-control-lg custom-form-control"
                      v-model="$v.form.category.$model" :class="{
                        'is-invalid': $v.form.category.$error,
                        'no-border': showCategories,
                      }" type="text" placeholder="Classe Terapêutica" @input="inputCategory"
                      @focus="setShowOptions('category', true)" @blur="setShowOptions('category', false)" />
                  </div>

                  <div v-if="showCategories" class="bg-white position-absolute select-custom" style="z-index: 1">
                    <ul class="list-group rounded-bottom">
                      <li class="list-group-item text-muted bg-light">
                        Selecione uma classe abaixo ou crie uma nova
                      </li>
                      <li class="list-group-item cursor-pointer" v-for="(item, index) in categoriesList" :key="index"
                        @click="($event) => selectOption('category', $event)">
                        {{ item?.category }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <hr class="my-0" />

            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <label class="text-subtitle mb-3">Mecanismo de ação</label>
                  <EditorInput :model="$v.form.mechanism_of_action" />
                </div>
              </div>
            </div>

            <hr class="my-0" />

            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <label class="text-subtitle mb-3">Tipo de receituário</label>
                  <TextInput :model="$v.form.type_of_prescription" type="text" placeholder="Tipo de receituário" />
                </div>
              </div>
            </div>

            <hr class="my-0" />

            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <label class="text-subtitle mb-3">Apresentações comerciais</label>
                  <EditorInput :model="$v.form.commercial_presentation" />
                </div>
              </div>
            </div>

            <hr class="my-0" />

            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <label class="text-subtitle mb-3">Posologia</label>
                  <EditorInput :model="$v.form.treatment_methods" />
                </div>
              </div>
            </div>

            <hr class="my-0" />

            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <label class="text-subtitle mb-3">Uso na gestação</label>
                  <div class="form-group mb-0">
                    <textarea rows="2" readonly id="useInPregnancyInput"
                      class="form-control form-control-lg custom-form-control" v-model="$v.form.pregnancy_use.$model"
                      :class="{
                        'is-invalid': $v.form.pregnancy_use.$error,
                        'no-border': showUsesInPregnancy,
                      }" style="background-color: #fff !important" placeholder="Uso na gestação"
                      @focus="setShowOptions('pregnancy_use', true)"
                      @blur="setShowOptions('pregnancy_use', false)"></textarea>
                  </div>

                  <div v-if="showUsesInPregnancy" class="bg-white position-absolute select-custom" style="z-index: 1">
                    <ul class="list-group rounded-bottom">
                      <li class="list-group-item text-muted bg-light">
                        Selecione uma categoria de uso na gestação
                      </li>
                      <li class="list-group-item cursor-pointer" v-for="(item, index) in usesInPregnancy" :key="index"
                        @click="($event) => selectOption('pregnancy_use', $event)
                          ">
                        {{ item?.title }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <hr class="my-0" />

            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <label class="text-subtitle mb-3">Uso na lactação</label>

                  <div class="form-group mb-0">
                    <textarea rows="2" readonly id="useInLactationInput"
                      class="form-control form-control-lg custom-form-control" v-model="$v.form.lactation_use.$model"
                      :class="{
                        'is-invalid': $v.form.lactation_use.$error,
                        'no-border': showUsesInLactation,
                      }" style="background-color: #fff !important" placeholder="Uso na lactação"
                      @focus="setShowOptions('lactation_use', true)"
                      @blur="setShowOptions('lactation_use', false)"></textarea>
                  </div>

                  <div v-if="showUsesInLactation" class="bg-white position-absolute select-custom" style="z-index: 1">
                    <ul class="list-group rounded-bottom">
                      <li class="list-group-item text-muted bg-light">
                        Selecione uma categoria de uso na lactação
                      </li>
                      <li class="list-group-item cursor-pointer" v-for="(item, index) in usesInLactation" :key="index"
                        @click="($event) => selectOption('lactation_use', $event)
                          ">
                        {{ item?.title }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <hr class="my-0" />

            <div class="card-body">
              <label class="text-subtitle mb-3">Efeitos colaterais</label>
              <div v-for="(item, index) in $v.form.side_effects.$each.$iter" v-bind:key="'side_effect_' + index">
                <div class="row">
                  <div class="col">
                    <TextInput :model="item.name" type="text" placeholder="Efeito colateral" />
                  </div>
                  <div v-if="index > 0" class="col-auto" @click="removeItem(index, 'side_effects')">
                    <div
                      class="form-control form-control-lg py-0 px-4 justify-content-center align-items-center d-flex cursor-pointer">
                      <i class="fas fa-trash-alt text-danger"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-subtitle text-primary cursor-pointer" @click="addContent('side_effect')">
                <i class="fas fa-circle-plus"></i>
                Adicionar
              </div>
            </div>

            <hr class="my-0" />

            <div class="card-body">
              <label class="text-subtitle mb-3">Referências</label>
              <div v-for="(item, index) in $v.form.references.$each.$iter" v-bind:key="'reference_' + index">
                <div class="row">
                  <div class="col">
                    <TextInput :model="item.name" type="text" placeholder="Referência" />
                  </div>
                  <div v-if="index > 0" class="col-auto" @click="removeItem(index, 'references')">
                    <div
                      class="form-control form-control-lg py-0 px-4 justify-content-center align-items-center d-flex cursor-pointer">
                      <i class="fas fa-trash-alt text-danger"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-subtitle text-primary cursor-pointer" @click="addContent('reference')">
                <i class="fas fa-circle-plus"></i>
                Adicionar
              </div>
            </div>
          </div>

          <div class="card" v-if="!id">
            <div class="card-body">
              <label class="text-subtitle mb-3">Nomes comerciais</label>
              <div v-for="(item, index) in $v.form.names.$each.$iter" v-bind:key="'names_' + index">
                <div class="row">
                  <div class="col">
                    <TextInput :model="item.name" type="text" placeholder="Referência" />
                  </div>
                  <div v-if="index > 0" class="col-auto" @click="removeItem(index, 'names')">
                    <div
                      class="form-control form-control-lg py-0 px-4 justify-content-center align-items-center d-flex cursor-pointer">
                      <i class="fas fa-trash-alt text-danger"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-subtitle text-primary cursor-pointer" @click="addContent('names')">
                <i class="fas fa-circle-plus"></i>
                Adicionar
              </div>
            </div>
          </div>

          <!-- <hr class="my-0" /> -->
          <!-- <div class="card-body"> -->
          <div class="row">
            <div class="col-12 text-center">
              <span class="text-danger text-center">{{
                errorMessage
              }}</span>
            </div>
          </div>
          <FormButton label="Salvar" :loading="submitting" @onClick="submit" :disabled="$v.$invalid" />
          <!-- </div> -->

        </form>
      </div>
    </Container>
  </div>
</template>

<script>
import AddMedicinesController from "./AddMedicinesController";
export default AddMedicinesController;
</script>

<style>
.select-custom {
  width: calc(100% - 48px);
}

.select-custom .list-group {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.select-custom .list-group-item {
  padding: 12px 20px;
  border-color: #d9d9d9;
}

.no-border {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
  