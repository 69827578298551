import { NetworkService } from "./NetworkService";

export default class AIFilesService {
  constructor() {
    this.network = new NetworkService();
  }

  static STATUS = {
    uploaded: "Disponível",
    pending: "Processando",
    deleted: "Removido",
  };

  static STATUS_COLOR = {
    uploaded: "bg-success-soft",
    pending: "bg-warning-soft",
    deleted: "bg-danger-soft",
  };

  create = (params) => {
    return this.network.postMultipart(this.network.endpoints.aiFiles, params);
  };
  list = (params) => {
    return this.network.get(this.network.endpoints.aiFiles, params);
  };
  show = (id) => {
    return this.network.get(`${this.network.endpoints.aiFiles}/${id}`);
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.aiFiles}/${id}`);
  };
  update = (id, params) => {
    return this.network.put(`${this.network.endpoints.aiFiles}/${id}`, params);
  };
}
