import { required } from "vuelidate/lib/validators";

export default {
  name: "AddMedicines",
  mounted() {
    if (this.id) {
      this.loadingItems = true;
      this.getData();
    }
    this.getCategories();
    this.getUsesInLactation();
    this.getUsesInPregnancy();
  },
  data() {
    return {
      id: this.$route.params.id,
      errorMessage: "",
      submitting: false,
      loadingItems: false,
      categories: [],
      usesInPregnancy: [],
      usesInLactation: [],
      typingTimer: null,
      showCategories: false,
      showUsesInPregnancy: false,
      showUsesInLactation: false,
      form: {
        compound: "",
        names: [{ name: "" }],
        // similar_names: "",
        category: "",
        mechanism_of_action: "",
        type_of_prescription: "",
        commercial_presentation: "",
        treatment_methods: "",
        pregnancy_use: "",
        lactation_use: "",
        side_effects: [{ name: "" }],
        references: [{ name: "" }],
      },
    };
  },
  validations: {
    form: {
      compound: { required },
      names: {
        $each: {
          name: { required },
        },
      },
      //   similar_names: { required },
      category: { required },
      mechanism_of_action: { required },
      type_of_prescription: { required },
      commercial_presentation: { required },
      treatment_methods: { required },
      pregnancy_use: { required },
      lactation_use: { required },
      side_effects: {
        $each: {
          name: { required },
        },
      },
      references: {
        $each: {
          name: { required },
        },
      },
    },
  },
  computed: {
    categoriesList() {
      return this.categories;
    },
  },
  methods: {
    async getCategories(q = "") {
      try {
        let res = await this.$medicinesService.listCategories({ q });
        this.$set(this, "categories", res.data);
      } catch (error) {
        console.log({ error });
      }
    },
    async getUsesInLactation() {
      try {
        let res = await this.$medicinesService.listUsesInLactation();
        this.$set(
          this,
          "usesInLactation",
          res.data.map((e) => ({ id: e, title: e }))
        );
      } catch (error) {
        console.log({ error });
      }
    },
    async getUsesInPregnancy() {
      try {
        let res = await this.$medicinesService.listUsesInPregnancy();
        this.$set(
          this,
          "usesInPregnancy",
          res.data.map((e) => ({ id: e, title: e }))
        );
      } catch (error) {
        console.log({ error });
      }
    },
    inputCategory(e) {
      let value = e.target.value;
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(async () => {
        this.getCategories(value);
      }, 200);
    },
    selectOption(key, e) {
      // Valor selecionado
      let value = e.target.innerText;

      // Alterara o valor na DOM
      this.changeInputValue(key, value);

      // Alterar o valor do form e fazer a validação
      this.$set(this.form, key, value);
      this.$v.form.category.$touch();
    },
    changeInputValue(key, value) {
      setTimeout(() => {
        let el;
        switch (key) {
          case "category":
            el = document.getElementById("categoryInput");
            break;
          case "pregnancy_use":
            el = document.getElementById("useInPregnancyInput");
            break;
          case "lactation_use":
            el = document.getElementById("useInLactationInput");
            break;
        }
        el.value = value;
        el.dispatchEvent(new Event("input"));
      }, 200);
    },
    setShowOptions(key, show) {
      setTimeout(() => {
        switch (key) {
          case "category":
            this.showCategories = show;
            break;
          case "pregnancy_use":
            this.showUsesInPregnancy = show;
            break;
          case "lactation_use":
            this.showUsesInLactation = show;
            break;
        }
      }, 200);
    },
    addContent(type) {
      switch (type) {
        case "side_effect":
          this.$set(this.form, "side_effects", [
            ...this.form.side_effects,
            { name: "" },
          ]);
          break;
        case "reference":
          this.$set(this.form, "references", [
            ...this.form.references,
            { name: "" },
          ]);
          break;
        case "names":
          this.$set(this.form, "names", [...this.form.names, { name: "" }]);
          break;
      }
    },
    removeItem(index, type) {
      let items = [...this.form[type]];
      items.splice(index, 1);
      this.$set(this.form, type, items);
    },
    async getData() {
      try {
        const res = await this.$medicinesService.show(this.id);
        let data = res.data;
        this.$set(this, "form", data);
        this.getCategories(data.category);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingItems = false;
      }
    },
    async submit() {
      try {
        this.errorMessage = "";
        let values = this.form;
        console.log(values);
        // return;
        this.submitting = true;
        var res;
        if (this.id) {
          res = await this.$medicinesService.update(this.id, values);
          this.$eventBus.$emit("showToast", {
            title: "Medicamento editado",
            msg: `Medicamento editado com sucesso`,
          });
        } else {
          res = this.$eventBus.$emit("showToast", {
            title: "Medicamento adicionado",
            msg: `Medicamento adicionado com sucesso`,
          });
          await this.$medicinesService.create(values);
        }
        this.$router.push({
          path: `${this.$paths.medicines.detail}/${res?.data?.id}`,
        });
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.submitting = false;
      }
    },
  },
};
