Number.prototype.toCurrency = function (amount) {
  if (!amount) {
    amount = this;
  }
  let res = String(amount);
  try {
    res = `R$${Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      .replace(/,/g, "*")
      .replace(/\./g, ",")
      .replace(/\*/g, ".")}`;
    return res;
  } catch (e) {
    console.log(e);
  } finally {
  }
};
