<template>
  <div id="admins_detail">
    <Navbar
      current="Detalhes do administrador"
      :link="$paths.admins.list"
      :linkName="'Administradores'"
    />
    <Sidebar />
    <Container>
      <Header
        title="Detalhes do Administrador"
        :btnLink="`${$paths.admins.edit}/${id}`"
        btnLabel="Editar"
        btnIcon="fa-solid fa-pen-to-square"
      >
        <template v-slot:button>
          <router-link
            :to="`${$paths.admins.password_update}/${id}`"
            tag="a"
            class="btn btn-white lift ms-3 text-muted me-3"
          >
            Alterar senha
          </router-link>
          <button @click="handleDelete" class="btn btn-del lift ms-3">
            <div class="fa-solid fa-times-circle me-1"></div>
            Remover
          </button>
        </template>
      </Header>

      <div class="card">
        <LoadingContainer v-if="loadingItems" />
        <div class="card-body" v-else>
          <h2 class="text-header">Informações</h2>
          <div class="row">
            <!-- <div class="col-12 col-xl-6">
                <TextInput :readOnly="true" label="Id" :value="`${item.id}`" />
              </div> -->
            <div class="col-12">
              <TextInput :readOnly="true" label="Nome" :value="item.name" />
            </div>
            <div class="col-12">
              <TextInput :readOnly="true" label="Email" :value="item.email" />
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import AdminDetailController from "./AdminDetailController";
export default AdminDetailController;
</script>
