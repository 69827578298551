<template>
  <div class="vh-100 d-flex align-items-center bg-dark">
    <div class="container-fluid" id="login_page">
      <div class="row justify-content-center">
        <div class="col-12 col-md-5 col-xl-4 my-5">
          <div class="align-items-center flex-column d-flex mb-5">
            <img
              src="@/assets/img/logo_3.png"
              alt="Diag Backoffice"
              width="200"
            />
            <span class="badge bg-light fw-bold text-center mt-4">
              {{ $envString }}
            </span>
          </div>

          <h1 class="text-pagetitle text-center text-light mb-3">
            Esqueci minha senha
          </h1>

          <p class="text-light text-center mb-5">
            Insira um email válido para a recuperação de senha
          </p>

          <form>
            <TextInput
              label="Email"
              :model="$v.form.email"
              type="email"
              class="text-white"
              placeholder="exemple@gmail.com"
            />
            <FormButton
              label="Recuperar"
              :loading="submitting"
              :disabled="$v.$invalid"
              class="btn w-100 btn-primary mb-3"
              @onClick="login"
            />
            <div class="text-center">
              <small class="text-light text-center">
                Lembrou da senha?
                <router-link tag="a" :to="$paths.login"> Acesse.</router-link>
              </small>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ForgotPasswordController from "./ForgotPasswordController";
export default ForgotPasswordController;
</script>
