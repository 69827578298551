import { required } from 'vuelidate/lib/validators';
import { Modal } from "bootstrap";
import ListControllerMixin from '../../utils/ListControllerMixin';

export default {
    mounted() {
        // NOTE: Usado para triggar os eventos de show, hide...
        this.createSubcategoryModal = new Modal(document.getElementById("create_subcategory"));
        this.editSubcategoryModal = new Modal(document.getElementById("edit_subcategory"));

        // NOTE: Usado para ouvir os eventos de shown, hidden...
        this.createSubcategoryModalEl = document.getElementById('create_subcategory')
        this.createSubcategoryModalEl.addEventListener('hidden.bs.modal', (event) => this.clearForm())

        this.editSubcategoryModalEl = document.getElementById('edit_subcategory')
        this.editSubcategoryModalEl.addEventListener('hidden.bs.modal', (event) => this.clearForm())

        this.loadingItems = true
        this.getData()
    },
    mixins: [ListControllerMixin],
    watch: {
        "$route.params"(value) {
            this.id = value.id
            this.loadingItems = true
            this.getData()
        }
    },
    data() {
        return {
            submitting: false,
            loadingDelete: false,
            parentCategory: null,
            id: this.$route.params.id,
            columns: [
                {
                    name: 'Título',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Descrição',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Criada em',
                    sortName: null,
                    order: null
                },
            ],
            form: {
                name: '',
                description: '',
            }
        }
    },
    validations: {
        form: {
            name: {
                required,
            },
            description: {}
        }
    },
    methods: {
        fetchPromise() {
            this.getContentCategory()
            return this.$contentsService.list({
                ...this.filters,
                content_category_id: this.$route.params.id,
                orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
            })
        },
        async createSubcategory() {
            try {
                let values = { ...this.form, ref_category_id: this.id }
                this.submitting = true
                let res = await this.$contentsService.createCategory(values)
                if (res.status) {
                    this.createSubcategoryModal.hide()
                    this.$eventBus.$emit('showToast', { title: `${this.form.name} criada`, msg: `Subcategoria foi criada com sucesso.` })
                    this.clearForm()
                    this.$router.push({ path: `${this.$paths.contents.subcategories.list}/${this.parentCategory.id}` })
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.submitting = false
            }
        },
        async deleteSubcategory() {
            this.editSubcategoryModal.hide()
            this.$eventBus.$emit("showConfirmationAlert", {
                message: "Deseja excluir essa categoria permanentemente?",
                confirmCallback: async () => {
                    try {
                        this.$set(this, `loadingDelete`, true);
                        let res = await this.$contentsService.deleteCategory(this.id)
                        if (res.status) {
                            this.$eventBus.$emit('showToast', { title: 'Categoria excluída', msg: `Categoria excluída com sucesso` })
                            this.$router.push({ path: `${this.$paths.contents.subcategories.list}/${this.parentCategory.ref_category_id}` })
                        }
                    } catch (e) {
                        console.log(e);
                    } finally {
                        this.$set(this, `loadingDelete`, false);
                    }
                },
            });
        },
        async editSubcategory() {
            try {
                this.submitting = true
                let res = await this.$contentsService.updateCategory(this.id, this.form)
                if (res.status) {
                    this.editSubcategoryModal.hide()
                    this.$eventBus.$emit('showToast', { title: `${this.form.name} editada`, msg: `Subcategoria foi editada com sucesso.` })
                    this.getContentCategory()
                    this.clearForm()
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.submitting = false
            }
        },
        async getContentCategory() {
            try {
                let res = await this.$contentsService.showCategory(this.id)
                if (res.status) {
                    res.data.type = 'category'
                    this.$set(this, 'parentCategory', res.data)
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.loadingItems = false
            }
        },
        setupForm() {
            this.$set(this.form, 'name', this.parentCategory.name)
            this.$set(this.form, 'description', this.parentCategory.description)
        },
        clearForm() {
            this.$set(this.form, 'name', '')
            this.$set(this.form, 'description', '')
        },
    }
}